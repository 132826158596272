<template>
  <div class="popup" v-if="visible == 1">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5 v-if="order != 0 && loader == 0">Szczegóły zamówienia #{{ order.id }}</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <loader v-if="loader"></loader>
      <div class="wrapper" v-if="order != 0 && loader == 0">
        <ul>
          <li v-for="(item, index) in order.OrderDetails" :key="index">
            <h6>{{ index + 1 }}.</h6>
            <h5 class="capitalize">{{ item.Service.ServiceType.name }}</h5>
            <h5 class="capitalize"><span>Pakiet:</span> {{ item.Service.name }}</h5>
            <h5><span>Okres:</span> {{ item.Service.period }} {{ getPeriod(item.Service.period_type) }}</h5>
            <h5><span>Rozpoczęcie:</span> {{ formatDate(item.start_at) }}</h5>
            <h5><span>Zakończenie:</span> {{ formatDate(item.end_at) }}</h5>
            <h5 class="capitalize"><span>Typ zamówienia: </span>{{ (item.OrderType.type) }}</h5>
            <h5><span>Cena brutto:</span> <span class="orange">{{ (item.price_netto * order.vat).toFixed(2) }} PLN</span></h5>
            <div v-if="item.OrderDetailsAddons">
              <div v-for="addon in item.OrderDetailsAddons" :key="addon.id">
                <h5><span>{{ addon.title }}</span></h5>
                <h5><span>Cena brutto: </span><span class="orange">{{ (addon.price_netto * order.vat).toFixed(2) }}</span></h5>
              </div>
            </div>
          </li>
        </ul>
        <div class="status">
          <h5><span>Status:</span> {{ getStatus(order.status) }}</h5>
        </div>
        <div class="price">
          <h5>{{ getPaymentText(order.status) }}:</h5>
          <h4>{{ (order.total_netto * order.vat).toFixed(2) }} PLN</h4>
        </div>
        <div class="flex" v-if="order.status == 0">
          <button class="default small reverse" :class="[getColorShadowClass()]" @click="updateOrder(order.id, 2)">Anuluj zamówienie</button>
          <button class="default small" :class="[getColorShadowClass()]" @click="updateOrder(order.id, 1)">Zapłać</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
  name: 'PopupOrder',
  components: {
    Loader,
  },
  data(){
    return{
      loader: 1,
      order: 0,
      color: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    orderId() {
      return this.$store.state.orderId;
    },
  },
  created(){
      this.color = localStorage.companyColor;
  },
  watch: {
    orderId(){
      this.getOrderDetails();
    },
    $route(to, from) {
      this.color = localStorage.companyColor;
    },
  },
  methods: {
    close(){
      this.$store.commit('changeOrderId', 0);
      this.order = 0;
      this.loader = 1;
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    formatDate(date){
      if(date == null){
        return 'Brak'        
      }
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays;
    },
    getPaymentText(status){
      switch(parseInt(status)){
        case 0:
          return 'Do zapłaty';
        break;
        case 1:
          return 'Zapłacono';
        break;
        case 2:
          return 'Wartość';
        break;
      }
    },
    getStatus(status){
      switch(parseInt(status)){
        case 0:
          return 'Nieopłacone';
        break;
        case 1:
          return 'Opłacone';
        break;
        case 2:
          return 'Anulowane';
        break;
      }
    },
    getColorShadowClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    getOrderDetails(){
      let self = this;
      this.axios
      .get(this.api + 'panel/order', { params: { orderId: this.orderId, nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status == 1){
          self.order = response.data.data;
          setTimeout(function(){ self.loader = 0; }, self.loaderTime);
        }
      });
    },
    updateOrder(id, status){
      let self = this;
      this.axios
      .put(this.api + 'panel/order',  { orderId: id, status: status, nip: localStorage.companyNip }, 
        { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status == 2){
          self.$store.commit('toggleReloadView');
          self.$store.commit('toggleAlert', {
            visibility: 1,
            type: 1,
            msg: response.data.msg
          });
        }else if(response.data.status == 1){
          // let win = window.open(response.data.href + response.data.token, '_blank');
          // win.focus();
          location.replace(response.data.href + response.data.token);
        }else{
          self.$store.commit('toggleAlert', {
            visibility: 1,
            type: 0,
            msg: response.data.msg
          });
        }
        self.$store.commit('changeOrderId', 0);
        self.close();
      });
    },
    getPeriod(period){
      switch(period){
        case 'months':
          return 'm-c';
        break;
      }
    }
  }
}
</script>
<style scoped lang="scss">
  div.loader { margin: 15px auto; }
  ul { margin: 0; padding: 0; list-style: none; }
  ul li { display: flex; flex-wrap: wrap; min-height: 90px; margin: 10px 0; padding: 0 10px; line-height: 30px; background: $grayLighter3; border-radius: 3px; font-weight: 500;
    color: $grayLight; }
  ul li h6 { margin: 0 5px; padding: 0; font-size: 13px; font-weight: 400; }
  ul li h5 { margin: 0 5px; padding: 0; font-size: 13px; font-weight: 400; }
  ul li h5.capitalize { text-transform: capitalize; }
  ul li h5 span { color: $grayLighter; }
  ul li h5 span.orange { color: $orangeLight; }
  div.status { margin: 15px 0; }
  div.status h5 { margin: 0; padding: 0; font-weight: 400; text-align: right; color: $grayLight; }
  div.status h5 span { color: $grayLighter; }
  div.price { display: flex; justify-content: space-between; align-items: center; height: 40px; margin: 15px 0; background: $orangeLight; border-radius: 3px; }
  div.price h5 { margin: 0 15px; padding: 0; font-weight: 400; font-size: 15px; color: rgba(255, 255, 255, 0.6); }
  div.price h4 { margin: 0 15px; padding: 0; font-weight: 500; font-size: 15px; color: rgba(255, 255, 255, 1); }
  div.flex { display: flex; justify-content: space-between; margin: 45px 0 15px 0; }
</style>
