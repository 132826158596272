<template>
  <nav>
    <div class="icon" @click="goBackward()">
      <i class="material-icons" :class="[getColorClass()]">arrow_back</i>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PanelNav',
  data(){
    return{
      color: 0,
    }
  },
  created(){
    this.color = localStorage.companyColor;
  },
  watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
    },
  },
  methods: {
    goBackward(){
      switch(this.$route.name){
        case 'Settings':
          this.$router.push({ name: 'Companies', params: { nip: localStorage.companyNip }});
        break;
        case 'Help':
          this.$router.push({ name: 'Companies', params: { nip: localStorage.companyNip }});
        break;
        case 'Dashboard':
          this.$router.push({ name: 'Companies', params: { nip: localStorage.companyNip }});
        break;
        case 'Company':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        case 'Services':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        case 'Service1':
          this.$router.push({ name: 'Services', params: { nip: localStorage.companyNip }});
        break;
        case 'Invoices':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        case 'Invoice':
          this.$router.push({ name: 'Invoices', params: { nip: localStorage.companyNip }});
        break;
        case 'Orders':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        case 'Documents':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        case 'Reservation':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        case 'VirtualOffice':
          this.$router.push({ name: 'Dashboard', params: { nip: localStorage.companyNip }});
        break;
        default:
          this.$router.go(-1);
      }
    },
    getColorClass(){
      if(typeof localStorage.companyColor == 'undefined'){
        return 'color-orange';
      }else if(this.$route.name == 'Settings'){
        return 'color-orange';
      }else{
        switch(parseInt(this.color)){
          case 1:
            return 'color-orange';
          case 2:
            return 'color-blue';
          case 3:
            return 'color-red';
          case 4:
            return 'color-green';
          case 5:
            return 'color-pink';
          case 6:
            return 'color-purple';
        }
      }
    },
  }
}
</script>
<style scoped lang="scss">
  nav { display: flex; justify-content: space-between; align-items: center; height: 60px; }

  nav div.icon { display: flex; justify-content: center; align-items: center; width: 40px; height: 40px; flex-shrink: 0; cursor: pointer; }
  nav div.icon i.material-icons { font-size: 34px; color: $grayLighter; }
  nav div.icon:hover i.material-icons { color: $orangeLight; }
  nav div.icon:hover i.material-icons.color-orange { color: rgb(245,87,39); }
  nav div.icon:hover i.material-icons.color-blue { color: rgb(62,187,251); }
  nav div.icon:hover i.material-icons.color-red { color: rgb(242,69,39); }
  nav div.icon:hover i.material-icons.color-green { color: rgb(39,241,155); }
  nav div.icon:hover i.material-icons.color-pink { color: rgb(193,63,242); }
  nav div.icon:hover i.material-icons.color-purple { color: rgb(102,27,245) }

  @media (min-width: 1000px) {
    div#panel.element-menu nav { display: none; }
  }
</style>
