<template>
  <div id="panel" :class="{ 'element-menu': elementMenu }">
    <the-header></the-header>
    <the-nav></the-nav>
    <div class="wrapper" @click="closeNotifications()">
      <router-view />
    </div>
    <notifications></notifications>
    <company-changer></company-changer>
    <alert></alert>
  </div>
</template>

<script>
import Alert from '@/components/Alert.vue'
import Notifications from '@/components/Notifications.vue'
import CompanyChanger from '@/components/CompanyChanger.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheNav from '@/components/TheNav.vue'

export default {
  name: 'Panel',
  components: {
    Alert,
    CompanyChanger,
    Notifications,
    TheHeader,
    TheNav
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    elementMenu(){
      return this.$store.state.elementMenu;
    },
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  methods:{
    closeNotifications(){
      this.clearNotifications();
      this.$store.commit('toggleNotifications', 0);
    },
    clearNotifications(){
      let self = this;
      this.axios
      .put(this.api + 'panel/notifications', { }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div#panel { background: $grayLighter5; }
  div.flex { display: flex; }
  div.wrapper { width: 100vw; height: calc(100vh - 60px); padding: 10px; overflow-y: overlay; }
</style>
