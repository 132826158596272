<template>
  <aside v-if="visibility">
    <div class="triangle"></div>
    <div class="wrapper">
      <header :class="{ borderless: notifications.length == 0 }">
        <h3>Nowe powiadomienia</h3>
        <h4 :class="[getColorClass()]">{{ newCount }}</h4>
      </header>
      <div class="content">
        <div class="notification" v-for="notification in notifications" :key="notification.data.id" :title="notification.data.text">
          <div class="icon">
            <i class="material-icons" :class="[getColorClass(notification.data.status)]">{{ getTypeIcon(notification.data.NotificationType.name) }}</i>
          </div>
          <div class="content">
            <h5>{{ notification.data.text }}</h5>
            <h4 :class="[getColorClass(notification.data.status)]">{{ notification.data.NotificationType.name }}</h4>
            <h6>{{ notification.formattedDate }}</h6>
          </div>
          <div class="close" title="Ukryj powiadomienie" @click="deleteNotification(notification.data.id)">
            <i class="material-icons" :class="[getColorClass()]">close</i>
          </div>
        </div>
      </div>
      <footer v-if="notifications.length != 0">
        <div :class="[getColorClass()]" @click="deleteNotifications()">
          <p>Ukryj wszystkie</p>
          <i class="material-icons">close</i>
        </div>
      </footer>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Notifications',
  data(){
    return{
      visibility: 0,
      notifications: 0,
      newCount: 0,
    }
  },
  computed:{
    api() {
      return this.$store.state.api;
    },
    elementNotifications(){
      return this.$store.state.elementNotifications;
    },
  },
  created(){
    if(this.elementNotifications == 1){
      this.visibility = 1;
    }else{
      this.visibility = 0;
    }
    this.getNotifications();
  },
  watch: {
    elementNotifications(){
      if(this.elementNotifications == 1){
        this.visibility = 1;
        this.clearNotifications();
      }else{
        this.visibility = 0;
        this.getNotifications();
      }
    },
  },
  methods: {
    getColorClass(status){
      if(status == 1){
        return 'gray';
      }else if(typeof localStorage.companyColor == 'undefined'){
        return 'orange';
      }else if(this.$route.name == 'Settings'){
        return 'orange';
      }else{
        switch(parseInt(localStorage.companyColor)){
          case 1:
            return 'orange';
          case 2:
            return 'blue';
          case 3:
            return 'red';
          case 4:
            return 'green';
          case 5:
            return 'pink';
          case 6:
            return 'purple';
        }
      }
    },
    getTypeIcon(type){
      switch(type){
        case 'system':
          return 'admin_panel_settings';
        break;
        case 'usługi':
          return 'api';
        break;
        case 'wirtualne biuro':
          return 'important_devices'
        break;
        default:
          return 'donut_large'
      }
    },
    getNotifications(){
      let self = this;
      this.axios
      .get(this.api + 'panel/notifications', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.notifications = response.data.data;
        self.newCount = response.data.newCount;
      });
    },
    clearNotifications(){
      let self = this;
      this.axios
      .put(this.api + 'panel/notifications', { }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
      });
    },
    deleteNotification(id){
      let self = this;
      this.axios
      .delete(this.api + 'panel/notification',  { data: { id: id }}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.getNotifications();
      });
    },
    deleteNotifications(){
      let self = this;
      this.axios
      .delete(this.api + 'panel/notifications',  { data: { }}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.getNotifications();
      });
    }
  }
}
</script>
<style scoped lang="scss">
  aside { display: flex; flex-direction: column; align-items: flex-end; width: 450px; position: fixed; top: 57px; right: 70px;  }
  aside div.triangle { width: 0; height: 0; margin-right: 95px; border-left: 10px solid transparent; border-right: 10px solid transparent; 
    border-bottom: 10px solid  #fff; }
  aside div.wrapper { width: 450px; min-height: 100px; max-height: calc(100vh - 60px - 10px - 10px); padding: 25px; background: #fff; overflow-y: overlay; 
    box-shadow: 0px 5px 10px $grayLighter2; }
  aside div.wrapper header { display: flex; justify-content: space-between; height: 50px; border-bottom: 1px solid $grayLighter3; }
  aside div.wrapper header.borderless { border: none; }
  aside div.wrapper header h3 { margin: 0; padding: 0; font-weight: 400; font-size: 18px; color: $grayLight; }
  aside div.wrapper header h4 { margin: 0; padding: 0; font-weight: 500; font-size: 18px; color: rgb(245,87,39); }
  aside div.wrapper header.borderless h3, aside div.wrapper header.borderless h4 { line-height: 50px; }
  aside div.wrapper div.notification { display: flex; align-items: center; min-height: 120px; border-bottom: 1px solid $grayLighter3; }
  aside div.wrapper div.notification:last-child { border-bottom: none; }
  aside div.wrapper div.notification div.icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 70px; height: 70px; 
    border-right: 1px solid $grayLighter3; }
  aside div.wrapper div.notification div.icon i.material-icons { font-size: 36px;}
  aside div.wrapper div.notification div.content { display: flex; flex-direction: column; width: 295px; }
  aside div.wrapper div.notification div.close { display: flex; justify-content: center; align-items: center; width: 35px; height: 35px; 
    align-self: flex-start; flex-shrink: 0; cursor: pointer; }
  aside div.wrapper div.notification div.close:hover i.material-icons { color: rgb(245,87,39);  }
  aside div.wrapper div.notification div.close i.material-icons { color: $grayLighter; font-size: 18px;  }
  aside div.wrapper div.notification h5 { margin: 10px 0 10px 20px; padding: 0; font-weight: 400; font-size: 14px; color: $blackLight; }
  aside div.wrapper div.notification h4 { margin: 0 0 0 20px; padding: 0; font-weight: 400; font-size: 17px; color: $orangeLight; text-transform: capitalize; }
  aside div.wrapper div.notification h6 { margin: 0 0 10px 20px; padding: 0; font-weight: 400; font-size: 12px; color: $grayLighter; }
  aside div.wrapper footer { display: flex; justify-content: flex-end; align-items: center; color: $grayLighter; }
  aside div.wrapper footer div { display: flex; align-items: center; }
  aside div.wrapper footer p { margin: 0; padding: 0; font-size: 15px; }
  aside div.wrapper footer i.material-icons { margin-left: 3px; font-size: 18px; }
  aside div.wrapper footer div:hover { color: rgb(245,87,39); cursor: pointer; }

  aside div.wrapper header h4.orange { color: rgb(245,87,39); }
  aside div.wrapper header h4.blue { color: rgb(62,187,251); }
  aside div.wrapper header h4.red { color: rgb(242,69,39); }
  aside div.wrapper header h4.green { color: rgb(39,241,155); }
  aside div.wrapper header h4.pink { color: rgb(193,63,242) }
  aside div.wrapper header h4.purple { color: rgb(102,27,245); }

  aside div.wrapper div.notification h4.gray { color: $grayLighter; }
  aside div.wrapper div.notification h4.orange {  color: rgb(245,87,39); }
  aside div.wrapper div.notification h4.blue { color: rgb(62,187,251); }
  aside div.wrapper div.notification h4.red { color: rgb(242,69,39); }
  aside div.wrapper div.notification h4.green { color: rgb(39,241,155); }
  aside div.wrapper div.notification h4.pink { color: rgb(193,63,242) }
  aside div.wrapper div.notification h4.purple { color: rgb(102,27,245); }

  aside div.wrapper div.notification div.icon i.material-icons.gray { color: $grayLighter2; }
  aside div.wrapper div.notification div.icon i.material-icons.orange {  color: rgb(245,87,39); }
  aside div.wrapper div.notification div.icon i.material-icons.blue { color: rgb(62,187,251); }
  aside div.wrapper div.notification div.icon i.material-icons.red { color: rgb(242,69,39); }
  aside div.wrapper div.notification div.icon i.material-icons.green { color: rgb(39,241,155); }
  aside div.wrapper div.notification div.icon i.material-icons.pink { color: rgb(193,63,242) }
  aside div.wrapper div.notification div.icon i.material-icons.purple { color: rgb(102,27,245); }

  aside div.wrapper div.notification div.close:hover i.material-icons.orange {  color: rgb(245,87,39); }
  aside div.wrapper div.notification div.close:hover i.material-icons.blue { color: rgb(62,187,251); }
  aside div.wrapper div.notification div.close:hover i.material-icons.red { color: rgb(242,69,39); }
  aside div.wrapper div.notification div.close:hover i.material-icons.green { color: rgb(39,241,155); }
  aside div.wrapper div.notification div.close:hover i.material-icons.pink { color: rgb(193,63,242) }
  aside div.wrapper div.notification div.close:hover i.material-icons.purple { color: rgb(102,27,245); }

  aside div.wrapper footer div.orange:hover { color: rgb(245,87,39); }
  aside div.wrapper footer div.blue:hover { color: rgb(62,187,251); }
  aside div.wrapper footer div.red:hover { color: rgb(242,69,39); }
  aside div.wrapper footer div.green:hover { color: rgb(39,241,155); }
  aside div.wrapper footer div.pink:hover { color: rgb(193,63,242) }
  aside div.wrapper footer div.purple:hover { color: rgb(102,27,245); }

  @media (max-width: 450px) {
    aside div.triangle { margin-right: 130px; }
    aside { width: calc(100vw - 20px); right: 10px; }
    aside div.wrapper { width: calc(100vw - 20px); }
  }
</style>
