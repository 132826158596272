<template>
  <nav :class="[getColorClass()]" v-if="visibility">
    <header :class="[getGradientColorClass()]">
      <h1><a href="#">AFK Panel Klienta</a></h1>
      <div class="close" @click="close()">
        <i class="material-icons">close</i>
      </div>
    </header>
    <ul>
      <li :class="{ active: activeRoute == 'Dashboard', inactive: activeRoute != 'Dashboard' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Dashboard', params: { nip: this.$route.params.nip }}"> 
          <i class="material-icons">home</i>
          <p>Głowna</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Company', inactive: activeRoute != 'Company' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Company', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">info</i>
          <p>Informacje</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Services' || activeRoute == 'Service' || activeRoute == 'Service1', inactive: activeRoute != 'Services' && activeRoute != 'Service' && activeRoute != 'Service1' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Services', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">api</i>
          <p>Usługi</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Orders', inactive: activeRoute != 'Orders' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Orders', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">content_paste</i>
          <p>Zamówienia</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Invoices' || activeRoute == 'Invoice', inactive: activeRoute != 'Invoices' && activeRoute != 'Invoice' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Invoices', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">sticky_note_2</i>
          <p>Faktury</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Documents', inactive: activeRoute != 'Documents' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Documents', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">content_copy</i>
          <p>Dokumenty</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == 'Reservation', inactive: activeRoute != 'Reservation' }">
        <div class="rect"></div>
        <router-link :to="{ name: 'Reservation', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">calendar_today</i>
          <p>Rezerwacja sali</p>
        </router-link>
      </li>
      <!--
      <li :class="{ active: activeRoute == getRouteName(service.ServiceType.id), inactive: activeRoute != getRouteName(service.ServiceType.id), disabled: service.status != 2 }"
      v-for="service in services" :key="service.ServiceType.id">
        <div class="rect"></div>
        <router-link :to="getPath(service.ServiceType.id)"> 
          <i class="material-icons">{{ getServiceIcon(service.ServiceType.id) }}</i>
          <p>{{ service.ServiceType.name }}</p>
        </router-link>
      </li>
      -->
   </ul>
   <ul>
     <li :class="{ active: activeRoute == 'Firmy', inactive: activeRoute != 'Firmy' }">
        <div class="rect"></div>
        <router-link to="/firmy" @click.native="close()"> 
          <i class="material-icons">apps</i>
          <p>Firmy</p>
        </router-link>
      </li>
     <li :class="{ active: activeRoute == '', inactive: activeRoute != 'd' }">
        <div class="rect"></div>
        <router-link to="/ustawienia" @click.native="close()"> 
          <i class="material-icons">settings</i>
          <p>Ustawienia</p>
        </router-link>
      </li>
      <li :class="{ active: activeRoute == '', inactive: activeRoute != 'd' }">
        <div class="rect"></div>
        <router-link to="" @click.native="logoutRequest()"> 
          <i class="material-icons">exit_to_app</i>
          <p>Wyloguj</p>
        </router-link>
      </li>
   </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheNav',
  data(){
    return{ 
      activeRoute: '',
      visibility: 0,
      color: 0,
      services: 0,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    elementMenu(){
      return this.$store.state.elementMenu;
    },
  },
  created(){
    this.activeRoute = this.$route.name;
    if(localStorage.elementMenu == 1){
      this.visibility = 1;
    }
    this.color = localStorage.companyColor;
    this.getServicesList();
  },
  watch: {
    $route(to, from) {
      this.activeRoute = this.$route.name;
      this.color = localStorage.companyColor;
      this.getServicesList();
      if(this.activeRoute == 'Companies' || this.activeRoute == 'Settings' || this.activeRoute == 'Help'){
        this.close();
      }
    },
    elementMenu(){
      if(this.elementMenu == 1){
        localStorage.elementMenu = 1;
        this.visibility = 1;
      }else if(typeof localStorage.elementMenu == 'undefined'){
        localStorage.setItem("elementMenu", 1);
        this.visibility = 1;
      }else{
        localStorage.elementMenu = 0;
        this.visibility = 0;
      }
      this.getServicesList();
    },
  },
  methods: {
    close(){
      this.$store.commit('toggleMenu', 0);
      localStorage.elementMenu = 0;
      this.visibility = 0;
    },
    logoutRequest(){
      let self = this;
      this.axios
      .put(this.api + 'auth/logout', {}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
      }).then(function () {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem('companyNip');
        localStorage.removeItem('companyName');
        localStorage.removeItem('companyColor');
        self.close();
        self.$router.push('/')
      });
    },
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'orange';
        case 2:
          return 'blue';
        case 3:
          return 'red';
        case 4:
          return 'green';
        case 5:
          return 'pink';
        case 6:
          return 'purple';
      }
    },
    getGradientColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    getServiceIcon(id){
      switch(id){
        case 1:
          return 'important_devices';
        break;
      }
    },
    getRouteName(id){
      switch(id){
        case 1:
          return 'VirtualOffice';
        break;
      }
    },
    getPath(id){
      switch(id){
        case 1:
          return '/' + this.$route.params.nip + '/wirtualne-biuro';
        break;
      }
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.services = response.data.data;
      });
    }
  }
}
</script>
<style scoped lang="scss">
  nav { display: flex; flex-direction: column; width: 250px; height: 100vh; position: fixed; top: 0; left: 0; background: #fff; overflow-y: auto; 
    box-shadow: 0px 5px 10px $grayLighter2; z-index: 2; }
  
  nav header { display: flex; justify-content: space-between; }
  
  nav header div.close { display: flex; justify-content: center; align-items: center; width: 60px; height: 60px; cursor: pointer; }
  nav header div.close i.material-icons { font-size: 26px; color: $grayLighter3; }
  nav header div.close:hover i.material-icons { color: #fff; }

  nav header h1 { margin: 0; padding: 0; text-indent: -9999px; }
  nav header h1 a { display: block; width: 100px; height: 60px; background: url('../assets/logo_30_white.png') no-repeat 50% 50%; text-decoration: none; }

  nav ul { margin: 0; padding: 0; list-style: none; overflow: hidden; }
  ul:nth-child(3) { margin-top: auto; }
  nav ul li { height: 50px; margin: 0; position: relative; border-top: 1px solid $grayLighter5; }
  nav ul li div.rect { display: none; margin-top: 0px; height: 50px; width: 4px; position: absolute; background: $orangeLight; }
  nav.orange ul li div.rect { background: rgb(245,87,39); }
  nav.blue ul li div.rect { background: rgb(62,187,251); }
  nav.red ul li div.rect { background: rgb(242,69,39); }
  nav.green ul li div.rect { background: rgb(39,241,155); }
  nav.pink ul li div.rect { background: rgb(193,63,242); }
  nav.purple ul li div.rect { background: rgb(102,27,245); }
  nav ul li a { display: flex; align-items: center; height: 50px; padding: 0 15px; text-decoration: none; }
  nav ul li a i.material-icons { color: $grayLight; font-size: 19px; }
  nav ul li a p { margin: 0 0 0 15px; padding: 0; font-size: 16px; color: $grayLight; font-weight: 400; text-transform: capitalize; }

  nav ul li.active { border-top: 1px solid rgba(255, 255, 255, 0.3); }
  nav ul li.active a i.material-icons { color: #fff; }
  nav ul li.active a p { color: #fff; }
  nav.orange ul li.active { background: rgb(245,87,39); background: linear-gradient(45deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%); }
  nav.blue ul li.active { background: rgb(62,187,251); background: linear-gradient(45deg, rgba(62,187,251,1) 0%, rgba(31,140,243,1) 100%); }
  nav.red ul li.active { background: rgb(242,69,39); background: linear-gradient(45deg, rgba(242,69,39,1) 0%, rgba(222,38,103,1) 100%); }
  nav.green ul li.active { background: rgb(39,241,155); background: linear-gradient(45deg, rgba(39,241,155,1) 0%, rgba(15,181,221,1) 100%); }
  nav.pink ul li.active { background: rgb(193,63,242); background: linear-gradient(45deg, rgba(193,63,242,1) 0%, rgba(222,38,103,1) 100%); }
  nav.purple ul li.active { background: rgb(102,27,245); background: linear-gradient(45deg, rgba(102,27,245,1) 0%, rgba(70,94,250,1) 100%); }

  nav ul li.inactive:hover { background: rgba(255, 255, 255, 0.2); }
  nav ul li.inactive:hover div.rect { display: block; }
  nav.orange ul li.inactive:hover a i.material-icons, nav.orange ul li.inactive:hover a p { color: rgb(245,87,39); }
  nav.blue ul li.inactive:hover a i.material-icons, nav.blue ul li.inactive:hover a p { color: rgb(62,187,251); }
  nav.red ul li.inactive:hover a i.material-icons, nav.red ul li.inactive:hover a p { color: rgb(242,69,39); }
  nav.green ul li.inactive:hover a i.material-icons, nav.green ul li.inactive:hover a p { color: rgb(39,241,155); }
  nav.pink ul li.inactive:hover a i.material-icons, nav.pink ul li.inactive:hover a p { color: rgb(193,63,242); }
  nav.purple ul li.inactive:hover a i.material-icons, nav.purple ul li.inactive:hover a p { color: rgb(102,27,245); }

  nav ul li.disabled a p { color: $grayLighter2; font-weight: 300; }
  nav ul li.disabled a i.material-icons { color: $grayLighter2; }
</style>
