<template>
    <div :class="isOpened()">
        <div class="custom-select" :tabindex="tabindex" @blur="open = false">
            <div class="selected" :class="{ open: open }" @click="open = !open">
                {{ selected }}
            </div>
            <div class="items" :class="{ selectHide: !open }">
                <div
                :class="[getColorShadowClass()]"
                v-for="(option, index) of options"
                :key="index"
                @click="selected = option; open = false; $emit('input', option);"
                >
                    {{ option }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: "Wybierz"
        },
        tabindex:{
            type: Number,
            required: false,
            default: 0
        },
    },
    data() {
        return {
            selected: this.default,
            open: false,
        };
    },
    methods: {
        getColorShadowClass(){
            switch(parseInt(localStorage.companyColor)){
                case 1:
                    return 'gradient-orange-hover';
                case 2:
                    return 'gradient-blue-hover';
                case 3:
                    return 'gradient-red-hover';
                case 4:
                    return 'gradient-green-hover';
                case 5:
                    return 'gradient-pink-hover';
                case 6:
                    return 'gradient-purple-hover';
            }
        },
        isOpened() {
            if(this.open) {
                return 'opened';
            } else {
                return '';
            };
        },
    },
}
</script>

<style lang="scss" scoped>
    .opened {
        margin-bottom: 300px;
    }

    .custom-select {
        position: relative;
        text-align: left;
        outline: none;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        white-space: nowrap;
        width: 100%;
    }

    .custom-select .selected {
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: $grayLight;
        padding-left: 1em;
        padding-right: 2em;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .selected.open {
        border: 1px solid;
        border-radius: 6px 6px 0px 0px;
    }

    .custom-select .selected:after {
        position: absolute;
        content: "";
        top: 50%;
        right: .6em;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: $grayLight transparent transparent transparent;
    }

    .custom-select .items {
        color: $grayLight;
        background-color: #fff;
        border-radius: 0px 0px 6px 6px;
        overflow: hidden;
        border-right: 1px solid;
        border-left: 1px solid;
        border-bottom: 1px solid;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .custom-select .items div {
        color: $grayLight;
        padding-left: 1em;
        padding-right: 0.5em;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .items div.gradient-orange-hover:hover { background: rgb(245,87,39); background: linear-gradient(45deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%); color: #fff; }
    .custom-select .items div.gradient-green-hover:hover { background: rgb(39,241,155); background: linear-gradient(45deg, rgba(39,241,155,1) 0%, rgba(15,181,221,1) 100%); color: #fff; }
    .custom-select .items div.gradient-blue-hover:hover { background: rgb(62,187,251); background: linear-gradient(45deg, rgba(62,187,251,1) 0%, rgba(31,140,243,1) 100%); color: #fff; }
    .custom-select .items div.gradient-purple-hover:hover { background: rgb(102,27,245); background: linear-gradient(45deg, rgba(102,27,245,1) 0%, rgba(70,94,250,1) 100%); color: #fff; }
    .custom-select .items div.gradient-pink-hover:hover { background: rgb(193,63,242); background: linear-gradient(45deg, rgba(193,63,242,1) 0%, rgba(222,38,103,1) 100%); color: #fff; }
    .custom-select .items div.gradient-red-hover:hover { background: rgb(242,69,39); background: linear-gradient(45deg, rgba(242,69,39,1) 0%, rgba(222,38,103,1) 100%); color: #fff; }
    .custom-select .items div.gradient-gray-hover:hover { background: rgb(59, 59, 59); background: linear-gradient(45deg, rgba(59, 59, 59, 1) 0%, rgba(210, 210, 212, 1) 100%); color: #fff; }

    .selectHide {
        display: none;
    }
</style>
