<template>
  <div class="documents" :class="{ center: loader  == 1 }">
    <panel-nav v-if="loader == 0"></panel-nav>
    <loader v-if="loader == 1"></loader>
    <div class="documents-list" v-if="loader == 0">
      <div 
      v-for="document in documentsList"
      :key="document.id"
      class="box document" 
      :class="[getColorClass()]" 
      @click="showVirtualOfficeHire(document.id)"
      >
        <header>
          <div class="icon">
            <i class="material-icons">content_copy</i>
          </div>
          <h5>{{ document.service }}</h5>
        </header>
        <h4>{{ document.name }}</h4>
        <h6>Utworzono: {{ document.date }}</h6>
      </div>
    </div>
    <popup-document-virtual-office-hire :id="choosenId"></popup-document-virtual-office-hire>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import PanelNav from '@/components/PanelNav.vue'
import PopupDocumentVirtualOfficeHire from '@/components/popups/DocumentVirtualOfficeHire.vue'

export default {
  name: 'Documents',
  components: {
    Loader,
    PanelNav,
    PopupDocumentVirtualOfficeHire
  },
  data(){
    return{
      loader: 1,
      color: 0,
      services: 0,
      documentsVisibility: [{ value: 0 }],
      documentsList: '',
      choosenId: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  created(){
    this.color = localStorage.companyColor;
    this.getServicesList();
    this.getDocumentsList();
  },
 watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
      this.reload();
    },
  },
  methods: {
    reload(){
        this.loader = 1;
        this.getServicesList();
    },
    showInvoice(){
      this.$router.push({ name: 'Invoice', params: { nip: this.$route.params.nip }});
    },
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    showVirtualOfficeHire(id){
      this.choosenId = id;
      this.$store.commit('togglePopup', 1);
    },
    zeroDocumentsArray(){
      for(let i = 0; i < this.documentsVisibility.length; i++){
        this.documentsVisibility[i].value = 0;
      }
    },
    fillDocumentsArray(){
      for(let i = 0; i < this.services.length; i++){
        if(this.services[i].ServiceType.id == 1 && this.services[i].status == 2){
          this.documentsVisibility[0].value = 1;
        }
      }
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.services = response.data.data;
        self.zeroDocumentsArray();
        self.fillDocumentsArray();
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
    getDocumentsList() {
      let self = this;
      this.axios
      .get(this.api + 'panel/document/list', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response) {
          self.documentsList = response.data;
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.documents-list { display: flex; flex-wrap: wrap; margin: 0 auto; padding: 10px; width: 1780px; }
  div.documents.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px); }

  div.document { display: flex; flex-direction: column; justify-content: space-between; width: 200px; height: 225px; cursor: pointer; }
  div.document header { display: flex; justify-content: space-between; opacity: 0.8; }
  div.document header div.icon { display: flex; justify-content: center; align-items: center; width: 21px; height: 21px; margin: 10px; }
  div.document header div.icon i.material-icons { color: #fff; font-size: 21px; }
  div.document header h5 { margin: 10px; padding: 0; font-weight: 300; text-align: right; font-size: 16px; color: #fff; text-transform: capitalize; }
  div.document h4 { margin: 10px 0; padding: 0 10px; font-weight: 400; color: #fff; text-align: center; font-size: 24px; }
  div.document h6 { width: 100%; height: 30px; margin: 13px 0 0 0; padding: 0; font-weight: 400; font-size: 12px; color: #fff; text-align: center; background: rgba(255, 255, 255, 0.1);
    line-height: 30px; }

  @media (max-width: 1790px) {
    div.documents-list { width: 1580px; }
  }
  @media (max-width: 1590px) {
    div.documents-list { width: 1370px; }
  }
  @media (max-width: 1380px) {
    div.documents-list { width: 1140px; }
  }
  @media (max-width: 1150px) {
    div.documents-list { width: 920px; }
  }
  @media (min-width: 1000px) {
    div#panel.element-menu div.documents { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.documents-list{ width: 100%; }
  }
  @media (max-width: 930px) {
    div.documents-list { width: 680px; }
  }
  @media (max-width: 700px) {
    div.documents-list { width: 460px; }
  }
  @media (max-width: 500px) {
    div.documents-list { width: 360px; }
    div.document { width: 150px; height: 175px; }
    div.document header div.icon { width: 25px; height: 25px; margin: 5px; }
    div.document header div.icon i.material-icons { font-size: 18px; }
    div.document header h5 { font-size: 14px; line-height: 28px; margin: 5px; font-size: 12px; }
    div.document h4 { font-size: 16px; }
    div.document h6 { height: 25px; margin: 13px 0 0 0; font-size: 10px; line-height: 25px; }
  }
  @media (max-width: 220px) {
    div.documents-list { width: 100%; }
  }
</style>
