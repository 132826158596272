<template>
  <div class="popup" v-if="visible == 3">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Informacje</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <p>Dodane osoby są przypisane do danej firmy. Gdy jest konieczność wybrania osób do podpisania umowy podczas zakupu usługi to można wybrać z dodanych tutaj osób. W trakcie kupowania usługi także można dodać nowe osoby.<br>Edycja lub dodanie osób NIE wpływa na obecną umowę oraz na umowę po przedłużeniu usługi. Aby zmienić osoby wpisane na umowie należy skontaktować się z Biurem Obsługi Klienta AFK.</p>
        <button class="default small" :class="[getColorShadowClass()]" @click="close()">Zamknij</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCompanyPersonsContractInfo',
  data(){
    return{
      header: '',
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    }
  }
}
</script>
<style scoped lang="scss">
  div.popup div.wrapper { display: flex; flex-direction: column; align-items: center; }
  div.popup div.wrapper p { margin: 15px 0; padding: 0; font-size: 14px; color: $grayLight; }
  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
