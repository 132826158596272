<template>
  <div class="services" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="flex"  v-if="loader == 0">
      <div class="box service" :title="getTitle(service.status)"
      :class="[getColorClass(service.status), getServiceClass(service.ServiceType.id), getServiceActivityClass(service.status)]" 
      @click="go(service.ServiceType.id, service.status)" 
      v-for="service in services" :key="service.ServiceType.id">
        <header>
          <h3>{{ service.ServiceType.name }}</h3>
          <h4>{{ getPacket(service.Service) }}</h4>
        </header>
        <div class="img"></div>
        <h5>Ważna do: <span>{{ formatDate(service.end_at, service.status) }}</span></h5>
      </div>
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Services',
  components: {
    PanelNav,
    Loader,
  },
  data(){
    return{
      services: 0,
      color: 0,
      loader: 1,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    }
  },
  created(){
    this.getServicesList();
    this.color = localStorage.companyColor;
  },
  watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
      this.reload();
    },
  },
  methods: {
    reload(){
      this.loader = 1;
      this.getServicesList();
    }, 
    getColorClass(status){
      if(status != 2 && typeof status != 'undefined'){
        return 'gradient-gray';
      }else{
        switch(parseInt(this.color)){
          case 1:
            return 'gradient-orange';
          case 2:
            return 'gradient-blue';
          case 3:
            return 'gradient-red';
          case 4:
            return 'gradient-green';
          case 5:
            return 'gradient-pink';
          case 6:
            return 'gradient-purple';
        }
      }
    },
    getServiceClass(id){
      switch(id){
        case 1:
          return 'virtual'
        break;
      }
    },
    getServiceActivityClass(status){
      if(status == 2){
        return 'active';
      }else{
        return 'inactive'
      }
    },
    getTitle(status){
      if(status == 2){
        return 'Zarządzaj usługą'
      }else{
        return 'Kup usługę aby móc nią zarządzać'
      }
    },
    go(service, status){
      switch(service){
        case 1:
          if(status == 2){
            this.$router.push({ name: 'Service1', params: { nip: localStorage.companyNip }});
          }else{
            let win = window.open('http://wb.afkcob.usermd.net/#/pakiety', '_blank');
            win.focus();
          }
        break;
      }
    },
    getPacket(service){
      if(service == null){
        return 'Brak pakietu'
      }else{
        return 'Pakiet ' + service.name;
      }
    },
    formatDate(date, status){
      if(status == 2){
        let formatted = new Date(date);
        let chosenDays = formatted.getDate();
        if(chosenDays < 10){
          chosenDays = '0' + chosenDays;
        }
        let chosenMonths = formatted.getMonth() + 1;
        if(chosenMonths < 10){
          chosenMonths = '0' + chosenMonths
        }
        return formatted.getFullYear() + '.' + chosenMonths + '.' + chosenDays;
      }else{
        return 'Brak'
      }
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.services = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
   }
}
</script>
<style scoped lang="scss">
  div.services.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px); }

  div.flex { display: flex; flex-wrap: wrap; width: 1080px; margin: 0 auto; }
  div.service { display: flex; flex-direction: column; justify-content: space-between; width: 250px; height: 250px; cursor: pointer; }
  div.service header { display: flex; flex-direction: column; justify-content: center; height: 90px; }
  div.service h3 { margin: 3px 0; padding: 0; text-align: center; font-weight: 400; font-size: 22px; color: #fff; text-transform: capitalize; }
  div.service h4 { margin: 3px 0; padding: 0; text-align: center; font-weight: 400; font-size: 17px; color: rgba(255, 255, 255, 0.9); text-transform: capitalize; }
  div.service div.img { width: 250px; height: 120px; margin-top: auto; }
  div.service h5 { height: 40px; margin: 0; padding: 0; line-height: 40px; text-align: center; color: #fff; font-weight: 300; font-size: 14px; 
    color: rgba(255, 255, 255, 0.7); }
  div.service h5 span { color: #fff; font-weight: 500; }

  div.service.payment.active div.img { background: url('../../assets/service_payment_active.jpg') no-repeat 50% 50%; }
  div.service.payment.inactive div.img { background: url('../../assets/service_payment_inactive.jpg') no-repeat 50% 50%; }
  div.service.virtual.active div.img { background: url('../../assets/service_virtual_active.jpg') no-repeat 50% 50%; }
  div.service.virtual.inactive div.img { background: url('../../assets/service_virtual_inactive.jpg') no-repeat 50% 50%; }
  div.service.consultacy.active div.img { background: url('../../assets/service_consultacy_active.jpg') no-repeat 50% 50%; }
  div.service.consultacy.inactive div.img { background: url('../../assets/service_consultacy_inactive.jpg') no-repeat 50% 50%; }
  div.service.marketing.active div.img { background: url('../../assets/service_marketing_active.jpg') no-repeat 50% 50%; }
  div.service.marketing.inactive div.img { background: url('../../assets/service_marketing_inactive.jpg') no-repeat 50% 50%; }

  @media (max-width: 1080px) {
    div.flex { width: 810px; }
  }
  @media (min-width: 1000px) {
     div#panel.element-menu div.services { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.flex { width: 100%; }
  }
  @media (max-width: 810px) {
    div.flex { width: 540px; }
  }
  @media (max-width: 540px) {
    div.flex { width: 270px; }
  }
</style>
