<template>
  <div id="authorization-page">
    <authorization></authorization>
    <footer>
      <p>&copy; AFK Panel Klienta 2021, Wszelkie prawa zastrzeżone.</p>
    </footer>
  </div>
</template>

<script>
import Authorization from '@/components/Authorization.vue'

export default {
  name: 'AuthorizationPage',
  components: {
    Authorization,
  },
  created(){
    if((typeof localStorage.accessToken != 'undefined' || typeof localStorage.refreshToken != 'undefined') && typeof localStorage.accountStatus == 'undefined'){
      this.$router.push({ name: 'Companies' });
    }
  }
}
</script>
<style scoped lang="scss">
  div#authorization-page { display: flex; flex-direction: column; height: 100%; background: url('../assets/background.webp') no-repeat 50% 50%; background-size: 100% 100%; }
  footer { background: $grayLight; margin-top: auto;}
  footer p { margin: 0; padding: 7px; color: #fff; font-weight: 400; font-size: 13px; text-align: center; 
    border-top: 1px solid rgba(255, 255, 255, 0.05); }
</style>
