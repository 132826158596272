<template>
  <div class="dashboard" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="tiles" v-if="loader == 0">
      <div class="box tile" :class="[getColorClass()]">
        <router-link :to="{ name: 'Company', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">info</i>
          <h3>Informacje</h3>
        </router-link>
      </div>
      <div class="box tile" :class="[getColorClass()]">
        <router-link :to="{ name: 'Services', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">api</i>
          <h3>Usługi</h3>
        </router-link>
      </div>
      <div class="box tile" :class="[getColorClass()]">
        <router-link :to="{ name: 'Orders', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">content_paste</i>
          <h3>Zamówienia</h3>
        </router-link>
      </div>
      <div class="box tile" :class="[getColorClass()]">
        <router-link :to="{ name: 'Invoices', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">sticky_note_2</i>
          <h3>Faktury</h3>
        </router-link>
      </div>
      <div class="box tile" :class="[getColorClass()]">
        <router-link :to="{ name: 'Documents', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">content_copy</i>
          <h3>Dokumenty</h3>
        </router-link>
      </div>
      <div class="box tile" :class="[getColorClass()]">
        <router-link :to="{ name: 'Reservation', params: { nip: this.$route.params.nip }}">
          <i class="material-icons">calendar_today</i>
          <h3>Rezerwacja sali</h3>
        </router-link>
      </div>
      <!--
      <div class="box tile" :class="[getColorClass(service.status)]" @click="go(service.status, service.ServiceType.name)" v-for="service in services" :key="service.service_id">
        <i class="material-icons">{{ getServiceIcon(service.ServiceType.id) }}</i>
        <h3>{{ service.ServiceType.name }}</h3>
        <h4 class="normal" v-if="service.status != 2">Usługa nieaktywna</h4>
        <h4 class="hover" v-if="service.status != 2">Kliknij aby uzyskać informacje</h4>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Dashboard',
  components: {
    PanelNav,
    Loader
  },
  data(){
    return{
      services: 0,
      loader: 1,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  created(){
    this.getServicesList();
  },
  watch:{
    $route(to, from){
      this.reload();
    }
  },
  methods: {
    reload(){
      this.loader = 1;
      this.getServicesList();
    }, 
    getColorClass(status){
      if(status != 2 && typeof status != 'undefined'){
        return 'gradient-gray';
      }else{
        switch(parseInt(localStorage.companyColor)){
          case 1:
            return 'gradient-orange';
          case 2:
            return 'gradient-blue';
          case 3:
            return 'gradient-red';
          case 4:
            return 'gradient-green';
          case 5:
            return 'gradient-pink';
          case 6:
            return 'gradient-purple';
        }
      }
    },
    getServiceIcon(id){
      switch(id){
        case 1:
          return 'important_devices';
        break;
      }
    },
    go(status, service_name){
      switch(service_name){
        case "wirtualne biuro":
          if(status == 2){
            this.$router.push({ name: 'VirtualOffice', params: { nip: this.$route.params.nip }})
          }else{
            let win = window.open('http://wb.afkcob.usermd.net/#/', '_blank');
            win.focus();
          }
        break;
      }
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.services = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
  },
}
</script>
<style scoped lang="scss">
  div.dashboard.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px); }

  div.tiles { display: flex; flex-wrap: wrap; align-content: flex-start; width: 1245px; margin: 0 auto;
    padding: 10px; }
  div.tile { display: flex; flex-direction: column; align-items: center; width: 225px; height: 250px; padding-top: 60px; cursor: pointer; }
  div.tile a { display: flex; flex-direction: column; align-items: center; width: 225px; height: 250px; text-decoration: none; }
  div.tile i.material-icons { color: rgba(255, 255, 255, 0.9); font-size: 75px; }
  div.tile h3 { margin: 25px 0 0 0; padding: 0; color:  rgba(255, 255, 255, 0.9); font-weight: 300; font-size: 22px; text-transform: capitalize; }
  div.tile h4 { margin: 0; padding: 0 20px; font-weight: 300; color: rgba(255, 255, 255, 0.5); text-align: center; font-size: 16px; text-align: center; }
  div.tile h4.normal { display: block; }
  div.tile h4.hover { display: none; }

  div.tile.gradient-gray:hover h4.normal { display: none; }
  div.tile.gradient-gray:hover h4.hover { display: block; }

  @media (max-width: 1245px) {
    div.tiles { width: 1000px; }
  }
  @media (max-width: 1000px) {
    div.tiles { width: 755px; }
  }
  @media (min-width: 1000px) {
    div#panel.element-menu div.dashboard { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.tiles { width: 100%; }
  }
  @media (max-width: 755px) {
    div.tiles { width: 510px; }
  }
  @media (max-width: 510px) {
    div.tiles { width: 310px; }
    div.tile { width: 125px; height: 140px; padding-top: 25px; }
    div.tile a { width: 125px; height: 150px; }
    div.tile i.material-icons { font-size: 40px; }
    div.tile h3 { margin: 10px 0 0 0; font-size: 15px; }
    div.tile h4 { font-size: 12px; }
    div.tile.gradient-gray:hover h4.normal { display: block; }
    div.tile.gradient-gray:hover h4.hover { display: none; }
  }
</style>
