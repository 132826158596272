import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_API,
    loaderTime: 350,
    alert:{
      visibility: 0,
      type: 0,
      msg: ''
    },
    popup: 0,
    elementMenu: 0,
    elementNotifications: 0,
    companyAdded: 0,
    companyEdit: 0,
    companyInfoEdit: 0,
    orderId: 0,
    reloadView: 0,
  },
  mutations: {
    toggleAlert(state, payload){
      state.alert.visibility = payload.visibility;
      state.alert.type = payload.type;
      state.alert.msg = payload.msg;
    },
    togglePopup(state, payload){
      state.popup = payload;
    },
    toggleMenu(state, payload){
      state.elementMenu = payload;
    },
    toggleNotifications(state, payload){
      state.elementNotifications = payload;
    },
    toggleCompanyAdd(state, payload){
      if(state.companyAdded == 1){
        state.companyAdded = 0;
      }else{
        state.companyAdded = 1;
      }
    },
    toggleCompanyEdit(state, payload){
      if(state.companyEdit == 1){
        state.companyEdit = 0;
      }else{
        state.companyEdit = 1;
      }
    },
    changeCompanyInfoEdit(state, payload){
      state.companyInfoEdit = payload;
    },
    changeOrderId(state, payload){
      state.orderId = payload;
    },
    toggleReloadView(state, payload){
      if(state.reloadView == 1){
        state.reloadView = 0;
      }else{
        state.reloadView = 1;
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
