<template>
  <div class="popup" v-if="visible == 1">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Dodaj firmę</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div v-if="state === 1">
          <div class="input-container" title="NIP">
            <header>
              <h6>NIP</h6>
            </header>
            <div class="input-box">
              <input type="text" class="data" placeholder="NIP" v-model="nip">
            </div>
            <p v-if="nipValidation.value == 1">{{ nipValidation.text }}</p>
          </div>
          <div class="flex">
            <button class="default small gradient-orange-shadow" @click="downloadData()">Pobierz dane</button>
          </div>
        </div>
        <div v-if="state === 2">
          <div 
          v-for="(key, index) in gusDataKeys"
          :key="'data' + index">
            <div class="input-container" v-if="gusData[key].value">
              <header>
                <h6>{{ gusData[key].title }}</h6>
              </header>
              <div class="input-box">
                <input type="text" class="data" placeholder="NIP" v-model="gusData[key].value" disabled="true">
              </div>
            </div>
          </div>
          <div class="flex">
            <button class="default small gradient-orange-shadow" @click="addCompany()">Dodaj</button>
          </div>
        </div>
        <div id="loader-wrapper" v-if="loading">
          <h4>Trwa pobieranie danych... <br>Proszę poczekać.</h4>
          <loader></loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validation from '../../functions/validation';
import Loader from '@/components/Loader.vue'

export default {
  name: 'PopupCompanyAdd',
  components: {
    Loader,
  },
  data(){
    return{
      nip: '',
      nipValidation: { value: 0, text: 'Podana wartość jest nieprawidłowa', },
      gusData: {},
      state: 1,
      loading: false,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    gusDataKeys() {
      return Object.keys(this.gusData);
    },
  },
  methods: {
    resetOnClose() {
      this.nip = '';
      this.nipValidation = { value: 0, text: 'Podana wartość jest nieprawidłowa', };
      this.gusData = {};
      this.state = 1;
      this.loading = false;
    },
    close(){
      this.resetOnClose();
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    async downloadData() {
      this.nipValidation = validation.validateNip(this.nip, this.nipValidation);
      if(this.nipValidation.value == 1) {  
        return
      }
      this.state = 0;
      this.loading = true;
      await this.axios
      .get(this.api + 'panel/gus', { params: { nip: this.nip } })
      .then(response => {
        if(response.data.status == 1) {
          this.gusData.companyName = {
            title: 'Nazwa firmy',
            value: response.data.data.Nazwa
          };
          this.gusData.nip = {
            title: 'Nip',
            value: response.data.data.Nip
          };
          this.gusData.regon = {
            title: 'Regon',
            value: response.data.data.Regon
          };
          this.gusData.city = {
            title: 'Miejscowość',
            value: response.data.data.Miejscowosc
          };
          this.gusData.postCode = {
            title: 'Kod pocztowy',
            value: response.data.data.KodPocztowy
          };
          this.gusData.street = {
            title: 'Ulica',
            value: response.data.data.Ulica
          };
          this.gusData.houseNumber = {
            title: 'Numer domu',
            value: response.data.data.NrNieruchomosci
          };
          this.gusData.apartmentNumber = {
            title: 'Numer lokalu',
            value: response.data.data.NrLokalu
          };
          this.state = 2;
          this.loading = false;
        } else {
          this.nipValidation.value = 1;
          this.nipValidation.text = response.data.msg;
          this.state = 1;
          this.loading = false;
        }
      });
    },
    async addCompany() {
      this.state = 0;
      this.loading = true;
      await this.axios
      .post(this.api + 'company/', { nip: this.nip })
      .then(response => {
        if(response.data.status == 1) {
          this.$emit('refresh');
          this.$emit('toggleAlert', 1, 'Firma została dodana pomyślnie');
          this.$store.commit('toggleCompanyAdd');
          this.close();
        } else {
          this.$emit('toggleAlert', 0, 'Dodanie firmy nie powiodło się');
          this.close();
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.popup div.wrapper { display: flex; flex-direction: column; align-items: center; }
  div.popup div.wrapper h4 { margin: 15px 0; padding: 0; text-align: center; font-weight: 400; font-size: 18px; }
  div.popup div.wrapper h4 span { color: $orangeDark; }
  div.popup div.wrapper h5 { margin: 15px 0; padding: 0; text-align: center; font-weight: 400; font-size: 14px; color: $grayLighter; }
  div.popup div.wrapper div.flex { display: flex; justify-content: space-around; width: 100%; }
  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
