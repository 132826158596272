<template>
  <div class="popup" v-if="visible == 4 || visible == 5">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>{{ header }}</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div class="input-container" title="Imię">
          <header>
            <h6>Imię</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Imię" v-model="name">
          </div>
        </div>
        <div class="input-container" title="Nazwisko">
          <header>
            <h6>Nazwisko</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Nazwisko" v-model="surname">
          </div>
        </div>
        <div class="input-container" :title="getIdentityCardText()">
          <header>
            <h6>{{ getIdentityCardText() }}</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" :placeholder="getIdentityCardText()" :disabled="visible == 5" v-model="pesel" @keyup.enter="enter()">
          </div>
        </div>
        <div class="input-container" v-if="visible == 4">
          <div class="input-checkbox">
            <div class="checkbox" @click="changeIdentityCard()">
                <i class="material-icons" v-if="identity_card == 2">done</i>
            </div>
            <p>Nie mam peselu</p>
          </div>
          <p class="checkbox"></p>
        </div>
        <div class="flex">
          <button class="default small" :class="[getColorShadowClass()]" @click="addPerson()" v-if="visible == 4">Dodaj</button>
          <button class="default small reverse" :class="[getColorShadowClass()]" @click="deletePerson()" v-if="visible == 5">Usuń</button>
          <button class="default small" :class="[getColorShadowClass()]" @click="updatePerson()" v-if="visible == 5">Edytuj</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCompanyPersonsPost',
  data(){
    return{
      header: '',
      name: '',
      surname: '',
      pesel: '',
      identity_card: 1,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    companyInfoEdit() {
      return this.$store.state.companyInfoEdit;
    },
  },
  watch: {
    visible(){
      this.name = '';
      this.surname = ''
      this.pesel = '';
      this.identity_card = 1;
      if(this.visible == 4 || this.visible == 5){
        this.getHeaderText();
      }
      if(this.visible == 5){
        this.name = this.companyInfoEdit.name;
        this.surname = this.companyInfoEdit.surname;
        this.pesel = this.companyInfoEdit.identity_card;
      }
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    enter(){
      if(this.visible == 4){
        this.addPerson();
      }else if(this.visible == 5){
        this.updatePerson();
      }
    },
    getHeaderText(){
      if(this.visible == 4){
        this.header = 'Dodaj nową osobę';
      }else if(this.visible == 5){
        this.header =  'Edytuj osobę';
      }
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    getIdentityCardText(){
      if(this.visible == 4){
        if(this.identity_card == 1){
          return 'Pesel';
        }else{
          return 'Paszport'
        }
      }else{
        if(this.companyInfoEdit.identity_card_type == 1){
          return 'Pesel';
        }else{
          return 'Paszport'
        }
      }
    },
    changeIdentityCard(){
      if(this.identity_card == 1){
        this.identity_card = 2;
      }else{
        this.identity_card = 1;
      }
    },
    addPerson(){
      let self = this;
      this.axios
      .post(this.api + 'panel/company/correspondence/person',  { name: this.name, surname: this.surname, pesel: this.pesel, identityCardType: this.identity_card, nip: localStorage.companyNip, }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          this.name = '';
          this.surname = ''
          this.pesel = '';
          this.identity_card = 1;
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    updatePerson(){
      let self = this;
      this.axios
      .put(this.api + 'panel/company/correspondence/person',  { name: this.name, surname: this.surname, pesel: this.pesel, identityCardType: this.identity_card, nip: localStorage.companyNip, }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          this.name = '';
          this.surname = ''
          this.pesel = '';
          this.identity_card = 1;
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    deletePerson(){
      let self = this;
      this.axios
      .delete(this.api + 'panel/company/correspondence/person',  { data: { pesel: this.pesel, nip: localStorage.companyNip, }}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          this.name = '';
          this.surname = ''
          this.pesel = '';
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.input-container { width: 300px; margin: 10px auto; }
  div.input-container div.input-box { width: 300px; }
  div.input-container div.input-box input { width: 300px; }
  div.popup div.wrapper div.flex { display: flex; justify-content: space-between; width: 300px; margin: 0 auto; }
  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
