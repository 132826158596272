<template>
  <div class="settings">
    <panel-nav></panel-nav>
    <div class="flex">
      <div class="box change-password">
        <header class="box">
          <h6>Zmiana hasła</h6>
        </header>
        <div class="input-container" title="Imię">
          <header>
            <h6>Obecne hasło</h6>
          </header>
          <div class="input-box">
            <input type="password" class="data" placeholder="Obecne hasło" v-model="password" @keyup.enter="changePassword()">
          </div>
        </div>
        <div class="input-container" title="Imię">
          <header>
            <h6>Nowe hasło</h6>
          </header>
          <div class="input-box">
            <input type="password" class="data" placeholder="Nowe hasło" v-model="password1" @keyup.enter="changePassword()">
          </div>
        </div>
        <div class="input-container" title="Imię">
          <header>
            <h6>Powtórz nowe hasło</h6>
          </header>
          <div class="input-box">
            <input type="password" class="data" placeholder="Powtórz nowe hasło" v-model="password2" @keyup.enter="changePassword()">
          </div>
        </div>
        <button class="default small gradient-orange-shadow" @click="changePassword()">Zmień hasło</button>
      </div>
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'

export default {
  name: 'Settings',
  components:{
    PanelNav
  },
  data(){
    return{
      password: '',
      password1: '',
      password2: ''
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
  },
  methods: {
    changePassword(){
      let msg = ''
      if(this.password == ''){
        this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Pole "Obecne hasło" nie może być puste' });
        return;
      }else if(this.password1 == ''){
        this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Pole "Nowe hasło" nie może być puste' });
        return;
      }else if(this.password2 == ''){
        this.$store.commit('toggleAlert', { visibility: 1, type: 0, msg: 'Pole "Powtórz nowe hasło" nie może być puste' });
        return;
      }
      let self = this;
      this.axios
      .put(this.api + 'panel/password', { password: this.password, password1: this.password1, password2: this.password2 }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          this.password = '';
          this.password1 = '';
          this.password2 = '';
        }
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; justify-content: center; }
  div.box { width: 390px; }
  div.box button { display: block; margin: 20px auto; }

  div.box.change-password div.input-container { width: 350px; margin: 20px auto; }

  @media (max-width: 410px) {
    div.box { width: calc(100vw - 20px); }
    div.box.change-password div.input-container { width: calc(100% - 20px); }
    div.input-container div.input-box, div.input-container div.input-box input { width: 100%; }
  }
</style>
