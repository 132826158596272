<template>
  <div class="invoices" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="invoices-list" v-if="loader == 0">
      <div class="box invoice" :class="[getColorClass()]" @click="showInvoice(invoice.id)" v-for="invoice in invoices" :key="invoice.id">
        <header>
          <div class="icon">
            <i class="material-icons">picture_as_pdf</i>
          </div>
          <h5># {{ invoice.id }}</h5>
        </header>
        <div class="middle">
          <div class="price">
            <h4>{{ invoice.priceNetto }} <span>PLN</span></h4>
            <h5>netto</h5>
          </div>
        </div>
        <h6>Wystawiona: {{ invoice.date }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Invoices',
  components: {
    PanelNav,
    Loader,
  },
  data(){
    return{
      color: 0,
      loader: 1,
      invoices: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  created(){
    this.color = localStorage.companyColor;
    this.getInvoicesList();
  },
  watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
      this.reload();
    },
  },
  methods: {
    reload(){
      this.loader = 1;
      this.getInvoicesList();
    },
    showInvoice(id){
      this.$route.params.invoiceId = id;
      this.$router.push({ name: 'Invoice', params: { nip: this.$route.params.nip, invoiceId: this.$route.params.invoiceId }});
    },
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    getInvoicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/invoice/list', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.invoices = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.invoices.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px); }
  div.invoices-list { display: flex; flex-wrap: wrap; margin: 0 auto; padding: 10px; width: 1780px; }

  div.invoice { display: flex; flex-direction: column; justify-content: space-between; width: 200px; height: 175px; cursor: pointer; }
  div.invoice header { display: flex; justify-content: space-between; opacity: 0.8; }
  div.invoice header div.icon { display: flex; justify-content: center; align-items: center; width: 21px; height: 21px; margin: 10px; }
  div.invoice header div.icon i.material-icons { color: #fff; font-size: 21px; }
  div.invoice header h5 { margin: 10px; padding: 0; font-weight: 300; text-align: center; font-size: 16px; color: #fff; }
  div.invoice div.middle { display: flex; justify-content: center; }
  div.invoice div.middle div.price { display: flex; flex-direction: column; justify-content: center; align-items: center; }
  div.invoice div.middle div.price h4 { margin: 0; padding: 0; font-weight: 300; font-size: 38px; color: #fff; }
  div.invoice div.middle div.price h4 span { font-weight: 400; font-size: 19px; }
  div.invoice div.middle div.price h5 { margin: -10px 0 0 0; padding: 0; font-weight: 300; font-size: 12px; color: rgba(255, 255, 255, 0.6); }
  div.invoice h6 { width: 100%; height: 30px; margin: 13px 0 0 0; padding: 0; font-weight: 400; font-size: 12px; color: #fff; text-align: center; background: rgba(255, 255, 255, 0.1);
    line-height: 30px; }

  @media (max-width: 1790px) {
    div.invoices-list { width: 1580px; }
  }
  @media (max-width: 1590px) {
    div.invoices-list { width: 1370px; }
  }
  @media (max-width: 1380px) {
    div.invoices-list { width: 1140px; }
  }
  @media (max-width: 1150px) {
    div.invoices-list { width: 920px; }
  }
  @media (min-width: 1000px) {
    div#panel.element-menu div.invoices { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.invoices-list { width: 100%; }
  }
  @media (max-width: 930px) {
    div.invoices-list { width: 680px; }
  }
  @media (max-width: 700px) {
    div.invoices-list { width: 460px; }
  }
  @media (max-width: 500px) {
     div.invoices-list { width: 360px; }
    div.invoice { width: 150px; height: 125px; }
    div.invoice header div.icon { width: 25px; height: 25px; margin: 5px; }
    div.invoice header div.icon i.material-icons { font-size: 18px; }
    div.invoice header h5 { margin: 5px; font-size: 14px; line-height: 28px; }
    div.invoice div.middle div.price h4 {  font-size: 26px; }
    div.invoice div.middle div.price h4 span { font-size: 14px; }
    div.invoice div.middle div.price h5 { margin: -10px 0 0 0; font-size: 10px; }
    div.invoice h6 { height: 25px; margin: 13px 0 0 0; font-size: 10px; line-height: 25px; }
  }
  @media (max-width: 220px) {
    div.invoices-list { width: 100%; }
  }
</style>
