<template>
  <div class="error404">
    <header class="error">
      <h5>Ups...</h5>
      <h6>Coś poszło nie tak...</h6>
    </header>
    <router-link to="/">Przejdź do strony głównej</router-link>
  </div>
</template>

<script>
export default {
  name: 'Error404',
}
</script>
<style scoped lang="scss">
  div.error404 { display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; padding-top: 60px; }
  header.error { height: 342px; margin: 0 auto; padding-left: 130px; background: url('../assets/error404.webp') no-repeat 0% 50%; }
  header.error h5 { margin: 0; padding: 0; font-size: 40px; font-weight: 500; color: $blackLight; }
  header.error h6 { margin: 0; padding: 0; font-size: 20px; font-weight: 400; color: $grayLight; }
  a { display: inline-block; padding: 14px 35px; font-size: 16px; background: $orangeDark; text-decoration: none; color: #fff; border-radius: 4px; }
  a:hover { padding: 12px 33px; background: #fff; border: 2px solid $orangeDark; color: $orangeDark; }

  @media (max-width: 350px) {
    header.error { width: 100vw; height: 500px; padding: 0; }
    header.error h5 { text-align: center; }
    header.error h6 { text-align: center;  }
  }
</style>
