<template>
  <div class="popup" v-if="visible == 8 || visible == 13">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>{{ getHeaderText() }}</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div class="input-container" title="Miasto">
          <header>
            <h6>Miasto</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Miasto" v-model="city">
          </div>
        </div>
        <div class="input-container" title="Ulica">
          <header>
            <h6>Ulica</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Ulica" v-model="street">
          </div>
        </div>
        <div class="input-container" title="Numer domu/mieszkania">
          <header>
            <h6>Numer domu/mieszkania</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Numer domu/mieszkania" v-model="houseNumber">
          </div>
        </div>
        <div class="input-container" title="Kod pocztowy">
          <header>
            <h6>Kod pocztowy</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Kod pocztowy" v-model="postCode" @keyup.enter="enter()">
          </div>
        </div>
        <div class="input-container" title="Dodatkowe uwagi">
          <header>
            <h6>Dodatkowe uwagi</h6>
          </header>
          <div class="input-box textarea">
            <textarea class="data" placeholder="Dodatkowe uwagi" v-model="comment"></textarea>
          </div>
        </div>
        <div class="flex">
          <button class="default small" :class="[getColorShadowClass()]" v-if="visible == 8" @click="addAddress()">Dodaj</button>
          <button class="default small" :class="[getColorShadowClass()]" v-if="visible == 13" @click="updateAddress()">Edytuj</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCompanyCorrAddress',
  data(){
    return{
      city: '',
      street: '',
      houseNumber: '',
      postCode: '',
      comment: '',
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    companyInfoEdit() {
      return this.$store.state.companyInfoEdit;
    },
  },
  watch: {
    visible(){
      if(this.visible == 13){
        this.city = this.companyInfoEdit.city;
        this.street = this.companyInfoEdit.street;
        this.houseNumber = this.companyInfoEdit.houseNumber;
        this.postCode = this.companyInfoEdit.postCode;
        this.comment = this.companyInfoEdit.comment;
      }
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getHeaderText(){
      if(this.visible == 8){
        return 'Dodaj adres do korespondecji';
      }else if(this.visible == 13){
        return 'Edytuj adres do korespondecji';
      }
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    enter(){
      if(this.visible == 8){
        this.addAddress();
      }else if(this.visible == 13){
        this.updateAddress();
      }
    },
    addAddress(){
      let self = this;
      this.axios
      .post(this.api + 'panel/company/correspondence/info',  { city: this.city, street: this.street, numberHouse: this.houseNumber, postCode: this.postCode, 
        comment: this.comment, nip: localStorage.companyNip, }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    updateAddress(){
      let self = this;
      this.axios
      .put(this.api + 'panel/company/correspondence/info',  { city: this.city, street: this.street, numberHouse: this.houseNumber, postCode: this.postCode, 
        comment: this.comment, nip: localStorage.companyNip, }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.input-container { width: 300px; margin: 10px auto; }
  div.input-container div.input-box { width: 300px; }
  div.input-container div.input-box input { width: 300px; }
  div.popup div.wrapper div.flex { display: flex; justify-content: space-between; width: 300px; margin: 0 auto; }
  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
