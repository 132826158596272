import Vue from 'vue'
import VueRouter from 'vue-router'

import Error404 from '../views/Error404.vue'

import AuthorizationPage from '../views/AuthorizationPage.vue'

import Panel from '../views/Panel.vue'
import Companies from '../views/Companies.vue'
import Dashboard from '../views/Dashboard.vue'

import Company from '../views/Company.vue'

import Services  from '../views/services/Services.vue'
import Service from '../views/services/Service.vue'

import Invoices from '../views/invoices/Invoices.vue'
import Invoice from '../views/invoices/Invoice.vue'

import Documents from '../views/Documents.vue'

import Orders from '../views/Orders.vue'

import Reservation  from '../views/Reservation.vue'

import VirtualOffice  from '../views/VirtualOffice.vue'

import Settings from '../views/Settings.vue'

import Help from '../views/Help.vue'

Vue.use(VueRouter)

const routes = [
  { path: '*', 
    redirect: '/404' 
  },  
  {
    path: '/404',
    name: 'Error404',
    component: Error404
  },
  {
    path: '/',
    name: 'AuthorizationPage',
    component: AuthorizationPage
  },
  {
    path: '/firmy',
    name: 'Panel',
    component: Panel,
    children: [
      {
        path: '/firmy',
        name: 'Companies',
        component: Companies
      },
      { 
        path: '/ustawienia',  
        component: Settings,
        name: 'Settings'
      },
      { 
        path: '/pomoc',  
        component: Help,
        name: 'Help'
      },
      {
        path: '/:nip',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/:nip/informacje', 
        component: Company,
        name: 'Company'
      },
      { 
        path: '/:nip/uslugi', 
        name: 'Services', 
        component: Services 
      },
      { 
        path: '/:nip/uslugi/wirtualne-biuro', 
        name: 'Service1', 
        component: Service 
      },
      { 
        path: '/:nip/uslugi/kadry-i-place', 
        name: 'Service2', 
        component: Service 
      },
      { 
        path: '/:nip/faktury', 
        component: Invoices,
        name: 'Invoices',
      },
      { 
        path: '/:nip/faktury/:invoiceId', 
        component: Invoice,
        name: 'Invoice',         
      },
      { 
        path: '/:nip/dokumenty', 
        component: Documents,
        name: 'Documents',
      },
      { 
        path: '/:nip/zamowienia', 
        component: Orders,
        name: 'Orders',
      },
      { 
        path: '/:nip/rezerwacja-sali', 
        component: Reservation,
        name: 'Reservation',
      },
      { 
        path: '/:nip/wirtualne-biuro', 
        component: VirtualOffice,
        name: "VirtualOffice"         
      },
    ]
  },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  routes
})

export default router
