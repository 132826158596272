<template>
  <div class="companies" :class="{ flex: loader == 1 || companiesList == '' }">
    <loader v-if="loader == 1"></loader>
    <h2 v-if="loader == 0 && companiesList != ''">Wybierz firmę do zarządzania</h2>
    <div class="flex" :class="{ center: companiesList == '' }" v-if="loader == 0">
      <div 
      title="Wybierz firmę" 
      v-for="(company, index) in companiesList"
      :key="'company' + index"
      :class="chooseColor(index)" 
      @click="chooseCompany(company.nip, company.company_name, index)">
        <header>
          <h4><span>NIP:</span> {{ company.nip }}</h4>
          <h5><span>Symbol:</span> {{ getSymbol(company.company_name) }}</h5>
        </header>
        <div>
          <h3>{{ company.company_name }}</h3>
          <h4>{{ company.company_type }}</h4>
        </div>
        <footer>
          <div>
            <p>{{ company.activeServices }}</p>
            <h5>Aktywne usługi</h5>
          </div>
          <div class="icon">
            <i class="material-icons">api</i>
          </div>
        </footer>
      </div>
      <div class="box company new" title="Dodaj firmę" @click="companyAdd()">
        <h4>Dodaj nową firmę</h4>
        <i class="material-icons">add</i>
      </div>
    </div>
    <button class="icon-alone gradient-orange-shadow" title="Dodaj firmę" @click="companyAdd()"><i class="material-icons">add</i></button>
    <popup-company-add 
    @toggleAlert="toggleAlert"
    @refresh="getCompaniesList"/>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import PopupCompanyAdd from '@/components/popups/CompanyAdd.vue'

export default {
  name: 'Companies',
  components: {
    Loader,
    PopupCompanyAdd,
  },
  data() {
    return {
      loader: 1,
      companiesList: '',
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    }
  },
  created() {
    this.getCompaniesList();
    localStorage.removeItem('companyNip');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyColor');
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  methods: {
    chooseCompany(nip, name, index){
      localStorage.setItem('companyNip', nip);
      localStorage.setItem('companyName', name);
      localStorage.setItem('companyColor', this.setColor(index));
      this.$router.push({ name: 'Dashboard', params: { nip: nip }});
    },
    chooseColor(index) {
      const classBeginning = 'box company gradient-';
      while(index > 5) {
        index -= 6;
      }
      switch(index) {
        case 0: return classBeginning + 'orange';
        case 1: return classBeginning + 'blue';
        case 2: return classBeginning + 'red';
        case 3: return classBeginning + 'green';
        case 4: return classBeginning + 'pink';
        case 5: return classBeginning + 'purple';
      }
    },
    setColor(index) {
      while(index > 5) {
        index -= 6;
      }
      return index + 1;
    },
    companyAdd(){
      this.$store.commit('togglePopup', 1);
    },
    getSymbol(name){
      let pattern = new RegExp("[a-zA-z]");
      if(pattern.test(name)){
        for(let i = 0; i < name.length; i++){
          if(pattern.test(name.charAt(i))){
            return name.charAt(i);
          }
        }
      }else{
        return name.charAt(0);
      }
    },
    async getCompaniesList() {
      let self = this;
      await this.axios
      .get(this.api + 'panel/companies', { headers: { Authorization: localStorage.accessToken }})
      .then(response => {
        if(response.data.status == 1) {
          this.companiesList = response.data.companiesList;
        }
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
    toggleAlert(type, msg) {
      this.$store.commit('toggleAlert', {
        visibility: 1,
        type: type,
        msg: msg
      });
    },
  },
}
</script>
<style scoped lang="scss">
  div.companies { width: 1280px; min-height: calc(100vh - 80px); margin: 0 auto; padding: 10px; overflow-y: overlay; box-sizing: border-box; }
  div.companies.flex { display: flex; flex-direction: column; justify-content: center; align-items: center }
  div.companies h2 { margin: 25px 0 35px 0; padding: 0; font-size: 34px; font-weight: 300; color: $grayLighter4; text-align: center; }
  div.flex { display: flex; flex-wrap: wrap; align-content: center; }
  div.flex.center { justify-content: center; }
  div.company { display: flex; flex-direction: column; width: 400px; height: 300px; cursor: pointer; overflow: hidden; overflow-y: overlay; }
  div.company header { display: flex; justify-content: space-between; height: 40px; border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  div.company header h4 { margin: 0; padding: 0 15px; font-size: 16px; font-weight: 300; color: #fff; line-height: 40px; }
  div.company header h4 span { color: rgba(255, 255, 255, 0.7); }
  div.company header h5 { width: 150px; margin: 0; padding: 0; font-size: 16px; font-weight: 300; color: #fff; 
    border-left: 1px solid rgba(255, 255, 255, 0.1); line-height: 40px; text-align: center; }
  div.company header h5 span { color: rgba(255, 255, 255, 0.7); }
  div.company div h3 { margin: 15px; padding: 0; color: #fff; font-weight: 400; font-size: 20px; }
  div.company div h4 { margin: 15px; padding: 0; color: rgba(255, 255, 255, 0.7); font-weight: 300; font-size: 16px; }
  div.company footer { display: flex; justify-content: space-between; align-items: flex-end; margin-top: auto; }
  div.company footer div { margin: 0 25px 25px 25px; }
  div.company footer div h5 { margin: 0; padding: 0; font-size: 13px; color:  rgba(255, 255, 255, 0.5); font-weight: 300; text-transform: uppercase; 
    letter-spacing: 1px; }
  div.company footer div p { margin: 0; padding: 0; font-size: 40px; color: #fff; font-weight: 300; }
  div.company footer div.icon { display: flex; justify-content: center; align-items: center; width: 80px; height: 80px; margin: 0 25px 25px 25px; }
  div.company footer div.icon i.material-icons { font-size: 80px; color: rgba(255, 255, 255, 0.07);  }

  div.company.new { justify-content: center; align-items: center; background: #F5F5F5; border: 2px solid $grayLighter2; box-shadow: 0px 0px 1px $grayLighter3; }
  div.company.new:hover { border: 2px solid $orangeLight; box-shadow: 0px 0px 1px $orangeLight; }
  div.company.new h4 { margin: 10px 0; padding: 0; color: $grayLighter2; font-weight: 300; font-size: 26px; }
  div.company.new i.material-icons { color: $grayLighter2; font-size: 30px; }
  div.company.new:hover h4 { color: $orangeLight; }
  div.company.new:hover i.material-icons { color: $orangeLight; }

  div.company::-webkit-scrollbar { width: 8px; height: 8px; }
  div.company::-webkit-scrollbar-button { width: 0px; height: 0px; }
  div.company::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.2); border: 0px none #ffffff; border-radius: 100px; }
  div.company::-webkit-scrollbar-thumb:hover { background: $grayLighter; }
  div.company::-webkit-scrollbar-thumb:active { background: $grayLighter; }
  div.company::-webkit-scrollbar-track { background: transparent; border: 0px none #ffffff; border-radius: 100px; }
  div.company::-webkit-scrollbar-track:hover { background: transparent; }
  div.company::-webkit-scrollbar-track:active { background: transparent; }
  div.company::-webkit-scrollbar-corner { background: transparent; }

  @media (max-width: 1280px) {
    div.companies { width: 860px; }
  }
  @media (max-width: 860px) {
    div.companies { width: 420px; }
  }
  @media (max-width: 700px) {
    div.companies h2 { margin: 10px 0 20px 0; font-size: 26px; }
  }
  @media (max-width: 420px) {
    div.companies { width: calc(100vw - 20px); }
    div.company { width: 100%; }
    div.company header h4, div.company header h5 { padding: 0 15px; font-size: 15px; }
    div.company header h5 { width: initial; }
    div.company div h3 { font-size: 18px; }
  }
</style>
