import { render, staticRenderFns } from "./CompanyDelete.vue?vue&type=template&id=30e11488&scoped=true&"
import script from "./CompanyDelete.vue?vue&type=script&lang=js&"
export * from "./CompanyDelete.vue?vue&type=script&lang=js&"
import style0 from "./CompanyDelete.vue?vue&type=style&index=0&id=30e11488&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e11488",
  null
  
)

export default component.exports