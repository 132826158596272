<template>
  <div class="popup" v-if="visible == 1">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Zmiana pakietu</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper" v-if="ready">
        <div class="warning">
          <h4>Uwaga!</h4>
          <p>W trakcie bieżącego okresu rozliczeniowego pakiet można zmienić tylko na wyższy. Zmiana pakietu na niższy może nastąpić jedynie od nowego okresu rozliczeniowego.</p>
        </div>
        <div class="flex">
          <div class="box packet" :class="[getColorGradientClass(packets[0]) , { active: active == 0 }]" @click="activatePacket(0)">
            <h4>Pakiet Mini</h4>
            <h3>{{ prices[0] }} PLN</h3>
            <h5>netto | m-c</h5>
            <h6 v-if="packets[0] != 2">{{ getText(packets[0]) }}</h6>
            <div class="active">
              <i class="material-icons">done</i>
            </div>
          </div> 
          <div class="box packet" :class="[getColorGradientClass(packets[1]), { active: active == 1 }]" @click="activatePacket(1)">
            <h4>Pakiet Standard</h4>
            <h3>{{ prices[1] }} PLN</h3>
            <h5>netto | m-c</h5>
            <h6 v-if="packets[1] != 2">{{ getText(packets[1]) }}</h6>
            <div class="active">
              <i class="material-icons">done</i>
            </div>
          </div>
          <div class="box packet" :class="[getColorGradientClass(packets[2]), { active: active == 2 }]" @click="activatePacket(2)">
            <h4>Pakiet Komfort</h4>
            <h3>{{ prices[2] }} PLN</h3>
            <h5>netto | m-c</h5>
            <h6 v-if="packets[2] != 2">{{ getText(packets[2]) }}</h6>
            <div class="active">
              <i class="material-icons">done</i>
            </div>
          </div>
        </div>
        <button class="default small check" :class="[getColorShadowClass()]" @click="checkPackets()">Sprawdź zawartość pakietów</button>
        <div class="price" v-if="packets[2] == 2">
          <h5>Koszt zmiany pakietu</h5>
          <div :class="[getColorClass()]">
            <h4>Netto:</h4>
            <p>{{ getNetto() }} PLN</p>
          </div>
          <div :class="[getColorClass()]">
            <h4>Brutto:</h4>
            <p>{{getBrutto(getNetto())}} PLN</p>
          </div>
        </div>
        <div class="flex" v-if="packets[2] == 2">
          <button class="default small reverse" :class="[getColorShadowClass()]" @click="close()">Anuluj</button>
          <button class="default small" :class="[getColorShadowClass()]" @click="changePacket()">Zmień i opłać</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicePacket',
  data(){
    return{
      ready: 0,
      packets: [0, 1, 2],
      prices: [0, 0, 0],
      packetId: 0,
      packetsInfo: 0,
      active: -1,
      endAt: 0,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
  },
  created(){
    this.getServicesList();
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getColorClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'color-orange';
        case 2:
          return 'color-blue';
        case 3:
          return 'color-red';
        case 4:
          return 'color-green';
        case 5:
          return 'color-pink';
        case 6:
          return 'color-purple';
      }
    },
    getColorGradientClass(number){
      if(number == 0 || number == 1){
        return 'gradient-gray';
      }else{
        switch(parseInt(localStorage.companyColor)){
          case 1:
            return 'gradient-orange';
          case 2:
            return 'gradient-blue';
          case 3:
            return 'gradient-red';
          case 4:
            return 'gradient-green';
          case 5:
            return 'gradient-pink';
          case 6:
            return 'gradient-purple';
        }
      }
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    getText(number){
      if(number == 0){
        return 'Niedostępny';
      }else if(number == 1){
        return 'Aktualny';
      }
    },
    activatePacket(number){
      if(this.packets[number] == 2){
        if(this.active == number){
          this.active = -1;
        }else{
          this.active = number;
        }
      }
    },
    checkPackets(){
      if(this.$route.name == 'Service1'){
        let win = window.open('https://wirtualnebiuro.afk-cob.pl/pakiety', '_blank');
        win.focus();
      }
    },
    getNetto(){
      if(this.active == -1){
        return '0.00';
      }else{
        let oneDay = 86400000;
        let endAt = new Date(this.endAt);
        let today = new Date();
        let daysDifference = Math.round((endAt.getTime() - today.getTime()) / oneDay);
        let actualPrice = this.packetsInfo[this.packetId - 1].price_netto;
        let period = this.packetsInfo[this.packetId - 1].period;
        let newPrice = 1;
        for(let i = 0; i < this.packetsInfo.length; i++){
          if(i >= (this.active * 3) && period == this.packetsInfo[i].period){
            newPrice = this.packetsInfo[i].price_netto;
            break;
          }
        }
        let priceDifference = (newPrice / 30) - (actualPrice / 30);
        return (priceDifference * daysDifference).toFixed(2);
      }
    },
    getBrutto(netto){
      return (parseInt(netto) * 1.23).toFixed(2);
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        for(let i = 0; i < response.data.data.length; i++){
          if(self.$route.name == 'Service1' && response.data.data[i].ServiceType.id == 1){
            self.packetId = response.data.data[i].Service.id;
            self.endAt = response.data.data[i].end_at;
          }
        }
        if(self.$route.name == 'Service1'){
          self.getVirtualOfficePacketsData();
        }
      });
    },
    getVirtualOfficePacketsData(){
      let self = this;
      this.axios
      .get(this.api + 'service/vo')
      .then(response => {
        self.packetsInfo = response.data.data;
        if(self.packetId == 3 || self.packetId == 6 || self.packetId == 9){
          self.prices = [response.data.data[2].price_netto, response.data.data[5].price_netto, response.data.data[8].price_netto]
        }else if(self.packetId == 2 || self.packetId == 5 || self.packetId == 8){
          self.prices = [response.data.data[1].price_netto, response.data.data[4].price_netto, response.data.data[7].price_netto]
        }else{
          self.prices = [response.data.data[0].price_netto, response.data.data[3].price_netto, response.data.data[6].price_netto]
        }
        if(self.packetId <= 3 ){
          self.packets = [1, 2, 2];
        }else if(self.packetId <= 6){
          self.packets = [0, 1, 2];
        }else{
          self.packets = [0, 0, 1];
        }
        self.ready = 1;
      });
    },
    changePacket(){
      if(this.active == -1){
        this.$store.commit('toggleAlert', {
          visibility: 1,
          type: 0,
          msg: 'Proszę wybrać pakiet'
        });
      }else{
        let self = this;
        this.axios
        .put(this.api + 'service/packet/virtualoffice',  { packet: this.active + 1, nip: localStorage.companyNip, }, 
          { headers: { Authorization: localStorage.accessToken } })
        .then(response => {
          if(response.data.status == 0){
            self.$store.commit('toggleAlert', {
              visibility: 1,
              type: response.data.status,
              msg: response.data.msg
            });
          }else if(response.data.status == 1){
            // let win = window.open(response.data.href + response.data.token, '_blank');
            // win.focus();
            location.replace(response.data.href + response.data.token);
          }
        });
      }
    },
  }
}
</script>
<style scoped lang="scss">
  div.popup div.wrapper { display: flex; flex-direction: column; align-items: center; }
  div.popup div.wrapper div.flex-around { display: flex; justify-content: space-around; width: 100%; }
  div.popup div.wrapper div.flex { display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%; }

  div.popup div.wrapper div.warning { margin: 10px 0; padding: 10px; border-radius: 4px; background: $red; }
  div.popup div.wrapper div.warning h4 { margin: 0; padding: 0; font-weight: 500; font-size: 14px; color: #fff; }
  div.popup div.wrapper div.warning p { margin: 0; padding: 0; font-size: 13px; color: rgba(255, 255, 255, 0.9); }
  div.popup div.wrapper button.check { margin: 10px auto 10px 0; font-size: 11px; }

  div.popup div.wrapper div.packet { display: flex; flex-direction: column; justify-content: center; width: 140px; height: 140px; margin: 10px 0; border-radius: 2px;
    cursor: pointer; position: relative; }
  div.popup div.wrapper div.packet.gradient-gray { cursor: default; }
  div.popup div.wrapper div.packet h4 { margin: 0; padding: 0; font-weight: 400; text-align: center; color: rgba(255, 255, 255, 0.9); font-size: 15px; }
  div.popup div.wrapper div.packet h3 { margin: 10px 0; padding: 0; font-weight: 400; text-align: center; color: #fff; font-size: 20px; }
  div.popup div.wrapper div.packet h5 { margin: 0; padding: 0; font-weight: 400; text-align: center; color: rgba(255, 255, 255, 0.8); font-size: 11px; }
  div.popup div.wrapper div.packet h6 { width: 100%; margin: 0; padding: 0; position: absolute; bottom: 8px; font-weight: 400; font-size: 9px; 
    color: rgba(255, 255, 255, 0.8); text-align: center; }
  div.popup div.wrapper div.packet div.active { display: none; flex-direction: column; justify-content: center; align-items: center; width: 140px; height: 140px; 
    position: absolute; top: 0; left: 0; background: rgba(255, 255, 255, 0.3); }
  div.popup div.wrapper div.packet div.active i.material-icons { color: $grayLighter; font-size: 70px; }
  div.popup div.wrapper div.packet.active div.active { display: flex; }

  div.popup div.wrapper div.price { width: 100%; margin: 20px 0; }
  div.popup div.wrapper div.price h5 { margin: 0; padding: 0; font-weight: 400; text-align: right; font-size: 16px; color: $grayLight; }
  div.popup div.wrapper div.price div { display: flex; justify-content: space-between; width: 250px; height: 35px; margin: 10px 0 10px auto; border-radius: 2px; }
  div.popup div.wrapper div.price div.color-orange { background: rgb(245,87,39); }
  div.popup div.wrapper div.price div.color-blue { background: rgb(62,187,251); }
  div.popup div.wrapper div.price div.color-red { background:rgb(242,69,39); }
  div.popup div.wrapper div.price div.color-green { background: rgb(39,241,155); ; }
  div.popup div.wrapper div.price div.color-pink { background: rgb(193,63,242); }
  div.popup div.wrapper div.price div.color-purple { background: rgb(102,27,245); }
  div.popup div.wrapper div.price h4 { margin: 0 10px; padding: 0; font-weight: 400; text-align: right; color: rgba(255, 255, 255, 0.9); font-size: 14px; 
    line-height: 35px; }
  div.popup div.wrapper div.price p { margin: 0 10px; padding: 0; font-size: 18px; color: #fff; line-height: 35px; }

  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
