<template>
  <div class="help">
    <panel-nav></panel-nav>
    <div class="flex">
      <div class="box">
        <h4>Pomoc techniczna</h4>
        <p>W razie jakichkolwiek problemów tecznicznych oraz pytań dotyczących świadczonych usług prosimy o kontakt mailowy lub telefoniczny.</p>
        <h5>bok@afkcob.pl</h5>
        <h5>+48 71 336 95 21</h5>
      </div>
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'

export default {
  name: 'Settings',
  components:{
    PanelNav
  },
  data(){
    return{
    }
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
  div.flex { display: flex; flex-wrap: wrap; justify-content: center; }
  div.box { width: 500px; }
  div.box h4 { margin: 20px; padding: 0; color: $orangeLight; font-weight: 500; font-size: 26px; text-align: center; }
  div.box p { margin: 10px 20px 50px 20px; padding: 0; font-size: 16px; text-align: center; }
  div.box h5 { margin: 10px 20px; padding: 0; color: $orangeLight; font-weight: 500; text-align: center; font-size: 18px; }

  @media (max-width: 500px) {
    div.box { width: calc(100vw - 20px); }
  }
</style>
