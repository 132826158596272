import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";

Vue.use(VueAxios, axios)

Vue.use(VueGtag, {
  config: { id: "G-P0W7ZEEEKQ" }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
