<template>
  <div class="popup" v-if="visible == 7">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Usuwanie firmy</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <h4>Czy na pewno chcesz usunąć? <br><span :class="[getColorClass()]">{{ companyName }}</span></h4>
        <h5>Ta czynność jest nieodwracalna.<br> Wszystkie informacje związane z firmą zostaną usunięte.</h5>
        <div class="flex">
          <button class="default small reverse" :class="[getColorShadowClass()]" @click="close()">Anuluj</button>
          <button class="default small" :class="[getColorShadowClass()]" @click="deleteCompany()">Usuń</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCompanyDelete',
  data(){
    return{
      companyName: 0,
    }
  },
  computed:{
    api() {
      return this.$store.state.api;
    },
    visible(){
      return this.$store.state.popup;
    },
  },
  watch: {
    visible(){
      this.companyName = localStorage.companyName;
    }
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getColorClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'color-orange';
        case 2:
          return 'color-blue';
        case 3:
          return 'color-red';
        case 4:
          return 'color-green';
        case 5:
          return 'color-pink';
        case 6:
          return 'color-purple';
      }
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    deleteCompany(){
      let self = this;
      this.axios
      .delete(this.api + 'company', { data: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          self.close();
          self.$router.push({ name: 'Companies'});
        }
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.popup div.wrapper { display: flex; flex-direction: column; align-items: center; }
  div.popup div.wrapper h4 { margin: 15px 0; padding: 0; text-align: center; font-weight: 400; font-size: 18px; }
  div.popup div.wrapper h5 { margin: 15px 0; padding: 0; text-align: center; font-weight: 400; font-size: 14px; color: $grayLighter; }
  div.popup div.wrapper div.flex { display: flex; justify-content: space-around; width: 100%; }
  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
