<template>
  <div class="popup" v-if="visible == 11 || visible == 12">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>{{ header }}</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper">
        <div class="input-container" title="Email">
          <header>
            <h6>Email</h6>
          </header>
          <div class="input-box">
            <input type="text" class="data" placeholder="Email" :disabled="visible == 12" v-model="email" @keyup.enter="enter()">
          </div>
        </div>
        <div class="flex">
          <button class="default small" :class="[getColorShadowClass()]" @click="addEmail()" v-if="visible == 11">Dodaj</button>
          <button class="default small reverse" :class="[getColorShadowClass()]" @click="deleteEmail()" v-if="visible == 12">Usuń</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCompanyCorrEmail',
  data(){
    return{
      header: '',
      email: '',
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
    companyInfoEdit() {
      return this.$store.state.companyInfoEdit;
    },
  },
  watch: {
    visible(){
      if(this.visible == 11 || this.visible == 12){
        this.getHeaderText();
      }
      if(this.visible == 12){
        this.email = this.companyInfoEdit;
      }
    },
  },
  methods: {
    close(){
      if(this.visible == 12){
        this.email = '';
      }
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    enter(){
      if(this.visible == 11){
        this.addEmail();
      }
    },
    getHeaderText(){
      if(this.visible == 11){
        this.header = 'Dodaj nowy email';
      }else if(this.visible == 12){
        this.header =  'Edytuj email';
      }
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    addEmail(){
      let self = this;
      this.axios
      .post(this.api + 'panel/company/correspondence/email',  { nip: localStorage.companyNip, email: this.email }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          this.email = '';
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    },
    deleteEmail(){
      let self = this;
      this.axios
      .delete(this.api + 'panel/company/correspondence/email', { data:  { nip: localStorage.companyNip, email: this.email } }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          this.email = '';
          self.close();
        }
        self.$store.commit('toggleCompanyEdit');
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
      });
    }
  },
}
</script>
<style scoped lang="scss">
  div.input-container { width: 300px; margin: 10px auto; }
  div.input-container div.input-box { width: 300px; }
  div.input-container div.input-box input { width: 300px; }
  div.popup div.wrapper div.flex { display: flex; justify-content: space-between; width: 300px; margin: 0 auto; }
  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
