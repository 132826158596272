import validator from 'validator';

const validationTexts = {
    empty: 'To pole nie może być puste',
    long: 'Podana wartość jest za długa',
    value: 'Podana wartość jest nieprawidłowa',
    requirements: 'Podana wartość nie spełnia wymagań',
    passwordComparision: 'Hasła nie są ze sobą zgodne',
    position: 'Wybierz stanowisko',
    repeated: 'Podana wartość została już przypisana innej osobie',
    number: 'Podana wartość może zawierać jedynie cyfry',
    password: 'Hasła nie są takie same',
    phoneNumber: 'Podany numer jest nieprawidłowy',
};

const validation = {
    validateEmail: (email, validValues) => {
        validValues.value = 1;
        if(!email) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(email)) {
            validValues.text = validationTexts.empty;
        } else if(email.length > 255) {
            validValues.text = validationTexts.long;
        } else if(!validator.isEmail(email)) {
            validValues.text = validationTexts.value;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validateString: (value, validValues, maxLength) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(value.length > maxLength) {
            validValues.text = validationTexts.long;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validatePosition: (value, validValues) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.position;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validateNumber: (value, validValues, length) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(value.length > length) {
            validValues.text = validationTexts.long;
        } else if(!validator.isInt(value)) {
            validValues.text = validationTexts.number;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validateOnlyLetterString: (value, validValues, length) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(value.length > length) {
            validValues.text = validationTexts.long;
        } else if(!validator.isAlpha(value)) {
            validValues.text = validationTexts.value;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validateNip: (nip, validValues) => {
        validValues.value = 1;
        let regex = RegExp('[a-zA-Z]');
        let regex1 = RegExp('\\s');
        let regex2 = RegExp('\\W');
        let chars = nip.split('');
        let sum = 0;
        if(nip.length == 10){
        for(let i = 0; i < chars.length; i++){
            switch(i){
            case 0:
                sum += chars[i] * 6;
                break;
            case 1:
                sum += chars[i] * 5;
                break;
            case 2:
                sum += chars[i] * 7;
                break;
            case 3:
                sum += chars[i] * 2;
                break;
            case 4:
                sum += chars[i] * 3;
                break;
            case 5:
                sum += chars[i] * 4;
                break;
            case 6:
                sum += chars[i] * 5;
                break;
            case 7:
                sum += chars[i] * 6;
                break;
            case 8:
                sum += chars[i] * 7;
                break;
            }
        }
        sum %= 11;
        }
        if(nip.length == 0){
            validValues.text = validationTexts.empty;
        }else if(regex.exec(nip)){
            validValues.text = validationTexts.value;
        }else if(regex1.exec(nip)){
            validValues.text = validationTexts.value;
        }else if(regex2.exec(nip)){
            validValues.text = validationTexts.value;
        }else if(nip.length != 10){
            validValues.text = validationTexts.value;
        }else if(chars[9] != sum){
            validValues.text = validationTexts.value;
        }else{
            validValues.value = 0;
        }
        return validValues;
    },
    validateIsNotEmpty: (value, validValues) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else {
            validValues.value = 0;
        }
        return validValues;

    },
    validatePostalCode: (value, validValues) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(!validator.isPostalCode(value, 'PL')) {
            validValues.text = validationTexts.value;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validateStringWithoutNumbers: (value, validValues, length) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(value.length > length) {
            validValues.text = validationTexts.long;
        } else if(value.match(/\d+/g)) {
            validValues.text = validationTexts.value;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validatePasswords: (password1, password2, condition, validValues1, validValues2) => {
        if(!password1) {
            validValues1.value = 1;
            validValues1.text = validationTexts.empty;
        } else if(validator.isEmpty(password1)){
            validValues1.value = 1;
            validValues1.text = validationTexts.empty;
        }else if(condition == 1){
            validValues1.value = 1;
            validValues1.text = validationTexts.requirements;
        }else if(password1.length > 128){
            validValues1.value = 1;
            validValues1.text = validationTexts.long;
        }else{
            validValues1.value = 0;
        }
        if(!password2) {
            validValues2.value = 1;
            validValues2.text = validationTexts.empty;
        } else if(validator.isEmpty(password2)){
            validValues2.value = 1;
            validValues2.text = validationTexts.empty;
        }else if(password1 != password2){
            validValues2.value = 1;
            validValues2.text = validationTexts.password;
        }else if(password2.length > 128){
            validValues2.value = 1;
            validValues2.text = validationTexts.long;
        }else{
            validValues2.value = 0;
        }
        return [validValues1, validValues2];
    },
    isEmpty: (value) => {
        return validator.isEmpty(value);
    },
    validateKrs: (value, validValues, krsState) => {
        validValues.value = 1;
        if(validator.isEmpty(value) && krsState == 2) {
            validValues.text = validationTexts.empty;
        } else if(!validator.isNumeric(value, { no_symbols: true }) && !validator.isEmpty(value)) {
            validValues.text = validationTexts.value;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validatePhoneNumber: (value, validValues) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(!validator.isMobilePhone(value, 'pl-PL')) {
            validValues.text = validationTexts.phoneNumber;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
    validatePesel: (value, validValues) => {
        validValues.value = 1;
        if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(value.length != 11) {
            validValues.text = 'Numer PESEL musi zawierać 11 cyfr';
        } else if(!validator.isNumeric(value, { no_symbols: true })) {
            validValues.text = validationTexts.value;
        } else {
            let controlNumbers = [1, 3, 7, 9];
            let controlDigit = 10;
            let pesel = value.split('');
            let sum = 0;
            let peselDigit;
            let j = 0;
            for(let i = 0; i < pesel.length - 1; i++) {
                if(j == 4) {
                    j = 0;
                }
                peselDigit = pesel[i] * controlNumbers[j];
                if(peselDigit >= 10) {
                    peselDigit = peselDigit.toString();
                    peselDigit = peselDigit.split('');
                    sum += parseInt(peselDigit[peselDigit.length - 1]);
                } else {
                    sum += peselDigit;
                }
                j++;
            }
            if(sum >= 10) {
                sum = sum.toString();
                sum = sum.split('');
                controlDigit -= parseInt(sum[sum.length - 1]);
            } else {
                controlDigit -= sum;
            }
            if(pesel[pesel.length - 1] == controlDigit) {
                validValues.value = 0;
            } else {
                validValues.text = validationTexts.value;
            }
        }
        return validValues;
    },
    validatePassport: (value, validValues) => {
        validValues.value = 1;
        if(!value) {
            validValues.text = validationTexts.empty;
        } else if(validator.isEmpty(value)) {
            validValues.text = validationTexts.empty;
        } else if(value.length > 30) {
            validValues.text = validationTexts.value;
        } else if(value.length < 3) {
            validValues.text = validationTexts.value;
        } else if (!validator.isAlphanumeric(value)) {
            validValues.text = validationTexts.value;
        } else {
            validValues.value = 0;
        }
        return validValues;
    },
};

export default validation; 
