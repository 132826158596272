<template>
  <div class="reservation">
    <panel-nav></panel-nav>
    <div class="wrapper" >
      <div class="box reservation-info">
        <header class="box">
          <h6>Rezerwacja sali</h6>
        </header>
        <div>
          <div class="img">
            <i class="material-icons">calendar_today</i>
          </div>
          <h4>Rezerwacja sali</h4>
          <h5>Aby zamówić rezerwację sali proszę zadzwonić do Biura Obsługi Klienta</h5>
          <h6>+48 71 336 95 21</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'VirtualOffice',
  components: {
    Loader,
    PanelNav,
  },
  data(){
    return{
    }
  },
  methods: {
    
  }
}
</script>
<style scoped lang="scss">
  div.wrapper { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 140px);  }

  div.box.reservation-info { width: 400px; }
  div.box.reservation-info div.img { display: flex; justify-content: center; align-items: center; width: 80px; height: 80px; margin: 20px auto; }
  div.box.reservation-info div.img i.material-icons { color: $grayLight; font-size: 70px; }
  div.box.reservation-info h4 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 18px; color: $blackLight; }
  div.box.reservation-info h5 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 13px; color: $grayLighter; }
  div.box.reservation-info div h6 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 15px; color: $orangeLight; }

  @media (min-width: 1000px) {
      div#panel.element-menu div.reservation { width: calc(100% - 250px); margin-left: 250px; }
  }

  @media (max-width: 410px) {
    div.box.reservation-info { width: calc(100vw - 20px); }
  }
</style>
