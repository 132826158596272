<template>
  <div id="company-changer" v-if="visible && companiesCount > 1">
    <div class="companies" v-if="active">
      <button 
      :title="getTitle(company.company_name)"
      v-for="(company, index) in companiesList"
      :key="'company' + index"
      :class="{ orange: getColor(index + 1) == 1, blue: getColor(index + 1) == 2, red: getColor(index + 1) == 3, 
      green: getColor(index + 1) == 4, pink: getColor(index + 1) == 5, purple: getColor(index + 1) == 6}"
      @click="chooseCompany(company.nip, company.company_name, getColor(index + 1))">{{ getSymbol(company.company_name) }}</button>
    </div>
    <div class="bottom" title="Szybkie wybieranie firmy">
      <button :class="{ orange: activeColor == 1, blue: activeColor == 2, red: activeColor == 3, green: activeColor == 4, pink: activeColor == 5, purple: activeColor == 6}" 
      v-if="!active" @click="activate()">{{ activeSymbol }}</button>
      <button class="close" title="Zamknij szybkie wybieranie" v-if="active" @click="close()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyChanger',
  data(){
    return{
      visible: 0,
      active: 0,
      activeSymbol: '',
      activeColor: '',
      companiesList: '',
      companiesCount: 0,
    }
  },
  created(){
    this.getActiveCompany();
    this.getCompaniesList();
    if(this.$route.name != 'Companies' && this.$route.name != 'Settings' && this.$route.name != 'Help'){
      this.visible = 1;
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    companyAdded() {
      return this.$store.state.companyAdded;
    }
  },
  watch: {
    $route(to, from) {
      if(to.name != 'Companies' && to.name != 'Settings' && to.name != 'Help' ){
        this.visible = 1;
      }else{
        this.visible = 0;
      }
      if((typeof localStorage.companyNip == "undefined" || typeof localStorage.companyName == "undefined" || typeof localStorage.companyColor == "undefined") 
      && typeof this.$route.params.nip != 'undefined'){
        this.getCompaniesList();
      }
      if(typeof localStorage.companyNip != "undefined" && localStorage.companyNip != this.$route.params.nip){
        this.getCompaniesList();
      }
      this.getActiveCompany();
    },
    companyAdded(){
      this.getActiveCompany();
      this.getCompaniesList();
    }
  },
  methods: {
    activate(){
      this.active = 1;
    },
    close(){
      this.active = 0;
    },
    getActiveCompany(){
      if(typeof localStorage.companyName != "undefined"){
        this.activeSymbol = this.getSymbol(localStorage.companyName);
        this.activeColor = localStorage.companyColor;
      }
    },
    getColor(number){
      if(number > 6){
        return number % 6;
      }else{
        return number;
      }
    },
    getSymbol(name){
      let pattern = new RegExp("[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]");
      if(pattern.test(name)){
        for(let i = 0; i < name.length; i++){
          if(pattern.test(name.charAt(i))){
            return name.charAt(i);
          }
        }
      }else{
        return name.charAt(0);
      }
    },
    getTitle(name){
      return 'Wybierz firmę - ' + name;
    },
    chooseCompany(nip, name, color){
      localStorage.companyNip = nip;
      localStorage.companyName = name;
      localStorage.companyColor = color;
      this.getActiveCompany();
      this.close();
      this.$store.commit('toggleNotifications', 0);
      let previousNip = this.$route.params.nip;
      this.$route.params.nip = nip;
      if(this.$route.params.nip != previousNip){
        this.$router.push({ name: this.$route.name, params: { nip: nip }});
      }else{
        this.$router.go()
      }
    },
    async getCompaniesList() {
      let self = this;
      await this.axios
      .get(this.api + 'panel/companies', { headers: { Authorization: localStorage.accessToken }})
      .then(response => {
        if(response.data.status == 1) {
          this.companiesList = response.data.companiesList;
          if((typeof localStorage.companyNip == "undefined" || typeof localStorage.companyName == "undefined" || typeof localStorage.companyColor == "undefined") 
          && self.companiesList.length != 0 && typeof this.$route.params.nip != 'undefined' && this.$route.name != 'Companies'){
            for(let i = 0; i < this.companiesList.length; i++){
              if(self.companiesList[i].nip == self.$route.params.nip){
                self.chooseCompany(self.companiesList[i].nip, self.companiesList[i].company_name, self.getColor(i + 1));
              }
            }
          }
          if(typeof localStorage.companyNip != "undefined" && localStorage.companyNip != self.$route.params.nip){
            for(let i = 0; i < this.companiesList.length; i++){
              if(self.companiesList[i].nip == self.$route.params.nip){
                self.chooseCompany(self.companiesList[i].nip, self.companiesList[i].company_name, self.getColor(i + 1));
              }
            }
          }
          self.companiesCount = self.companiesList.length;
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div#company-changer { width: 50px; min-height: 50px; max-height: 700px; position: fixed; bottom: 50px; right: 50px; }
  div#company-changer button { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; margin-top: 15px; border: 0; 
    outline: 0; border-radius: 50%; background: $orangeDark; box-shadow: 0 0 5px $orangeDark; cursor: pointer; color: #fff; text-transform: uppercase;
    font-size: 18px; }
  div#company-changer div.companies button:first-child { margin-top: 0; }

  div#company-changer button.active { border: 3px solid #00E676; box-shadow: 0 0 5px #00E676; }
  div#company-changer button.close { background: $grayLight; box-shadow: 0 0 5px $grayLight; }

  div#company-changer button.orange { background: rgb(245,87,39); background: linear-gradient(45deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%);
    box-shadow: 0px 0px 5px rgb(245,87,39); }
  div#company-changer button.blue { background: rgb(62,187,251); background: linear-gradient(45deg, rgba(62,187,251,1) 0%, rgba(31,140,243,1) 100%);
    box-shadow: 0px 0px 5px rgb(62,187,251); }
  div#company-changer button.green { background: rgb(39,241,155); background: linear-gradient(45deg, rgba(39,241,155,1) 0%, rgba(15,181,221,1) 100%);
    box-shadow: 0px 0px 5px rgb(39,241,155); }
  div#company-changer button.pink { background: rgb(193,63,242); background: linear-gradient(45deg, rgba(193,63,242,1) 0%, rgba(222,38,103,1) 100%);
   box-shadow: 0px 0px 5px rgb(193,63,242); }
  div#company-changer button.purple { background: rgb(102,27,245); background: linear-gradient(45deg, rgba(102,27,245,1) 0%, rgba(70,94,250,1) 100%);
    box-shadow: 0px 0px 5px rgb(102,27,245); }
  div#company-changer button.red { background: rgb(242,69,39); background: linear-gradient(45deg, rgba(242,69,39,1) 0%, rgba(222,38,103,1) 100%);
    box-shadow: 0px 0px 5px rgb(242,69,39); }

  @media (max-width: 700px) {
    div#company-changer { width: 45px; min-height: 45px; bottom: 20px; right: 20px; }
    div#company-changer button { width: 45px; height: 45px; }
  }
</style>
