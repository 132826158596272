<template>
  <div class="service" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="flex" v-if="loader == 0">
      <div class="box tile-square-static">
        <h5>Nazwa usługi</h5>
        <i class="material-icons">api</i>
        <h4>{{ service.ServiceType.name }}</h4>
      </div>
      <div class="box tile-square-static">
        <h5>Typ pakietu</h5>
        <i class="material-icons">calendar_today</i>
        <h4>Pakiet {{ service.Service.name }}</h4>
      </div>
      <div class="box tile-square-static">
        <h5>Zakończenie usługi</h5>
        <i class="material-icons">calendar_today</i>
        <h4>{{ formatDate(service.end_at) }}</h4>
      </div>
    </div>
    <div class="flex" v-if="loader == 0">
      <div class="box tile-square" :class="[getColorClass()]">
        <a target="_blank" rel="noopener noreferrer" href="https://wirtualnebiuro.afk-cob.pl/">
          <i class="material-icons">info</i>
          <h4>Informacje</h4>
        </a>
      </div>
      <div class="box tile-square" :class="[getColorClass()]" @click="showPopupServicePacket()">
        <i class="material-icons">edit</i>
        <h4>Zmień pakiet</h4>
      </div>
      <div class="box tile-square" :class="[getColorClass()]" @click="showPopupServiceExtend()">
        <i class="material-icons">calendar_today</i>
        <h4>Przedłuż</h4>
      </div>
    </div>
    <popup-service-packet></popup-service-packet>
    <popup-service-extend></popup-service-extend>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import PopupServicePacket from '@/components/popups/ServicePacket.vue'
import PopupServiceExtend from '@/components/popups/ServiceExtend.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Service',
  components: {
    PanelNav,
    PopupServicePacket,
    PopupServiceExtend,
    Loader
  },
  data(){
    return{
      color: 0,
      loader: 1,
      service: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    }
  },
  created(){
    this.color = localStorage.companyColor;
    this.getServicesList();
  },
  watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
      this.reload();
    },
  },
   methods: {
     reload(){
      this.loader = 1;
      this.getServicesList();
    }, 
    showPopupServicePacket(){
      this.$store.commit('togglePopup', 1);
      document.body.style = "overflow-y: hidden";
    },
    showPopupServiceExtend(){
      this.$store.commit('togglePopup', 2);
      document.body.style = "overflow-y: hidden";
    },
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    formatDate(date){
      let formatted = new Date(date);
      let chosenDays = formatted .getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = formatted .getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      return formatted.getFullYear() + '.' + chosenMonths + '.' + chosenDays;
    },
    getServiceDetails(services){
      let id = parseInt(this.$route.name.replace('Service',''));
      for(let i = 0; i < services.length; i++){
        if(services[i].ServiceType.id == id){
          this.service = services[i];
          if(this.service.status != 2){
            this.$router.push({ name: 'Services', params: { nip: localStorage.companyNip }});
          }
        }
      }
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.getServiceDetails(response.data.data);
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
   }
}
</script>
<style scoped lang="scss">
  div.service.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px); }
  div.flex { display: flex; flex-wrap: wrap; justify-content: center; }

  @media (min-width: 1000px) {
    div#panel.element-menu div.service { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.flex { width: 100%; }
  }
</style>
