<template>
  <div class="popup" v-if="visible == 2">
    <div class="shadow" @click="close()"></div>
    <div class="container">
      <header>
        <h5>Przedłuż usługę</h5>
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      </header>
      <div class="wrapper" v-if="ready">
        <div class="flex">
          <div class="box date">
            <header class="box">
              <h6>Obecna data zakończnia usługi</h6>
            </header>
            <h4>{{ formatDate(date) }}</h4>
          </div>
          <div class="box date">
            <header class="box">
              <h6>Nowa data zakończnia usługi</h6>
            </header>
            <h4 :class="[getColorClass()]">{{ getNewDate() }}</h4>
          </div>
        </div>
        <div class="flex">
          <div class="box month" :class="[getColorGradientClass(), { active: month == 3 }]" @click="activateMonth(3)">
            <h3>3</h3>
            <h5>m-c</h5>
            <div class="active">
              <i class="material-icons">done</i>
            </div>
          </div>
          <div class="box month" :class="[getColorGradientClass(), { active: month == 6 }]" @click="activateMonth(6)">
            <h3>6</h3>
            <h5>m-c</h5>
            <div class="active">
              <i class="material-icons">done</i>
            </div>
          </div>
          <div class="box month" :class="[getColorGradientClass(), { active: month == 12 }]" @click="activateMonth(12)">
            <h3>12</h3>
            <h5>m-c</h5>
            <div class="active">
              <i class="material-icons">done</i>
            </div>
          </div>
        </div>
        <button class="default small change" :class="[getColorShadowClass()]" @click="changePacketsVisibility()">{{ getTextChangePacketVisibility() }}</button>
        <div class="change-packet" v-if="packetVisibility">
          <div class="warning">
            <h4>Uwaga!</h4>
            <p>Zmiana pakietu nastąpi dopiero gdy rozpocznie się nowy okres rozliczeniowy.</p>
          </div>
          <div class="flex">
            <div class="box packet" :class="[getColorGradientClass() , { active: chosenPacket == 1 }]" @click="changePacket(1)">
              <h4>Pakiet Mini</h4>
              <h3>{{ packetPrices[0] }} PLN</h3>
              <h5>netto | m-c</h5>
              <div class="active">
                <i class="material-icons">done</i>
              </div>
            </div> 
            <div class="box packet" :class="[getColorGradientClass(), { active: chosenPacket  == 2 }]" @click="changePacket(2)">
              <h4>Pakiet Standard</h4>
              <h3>{{ packetPrices[1] }} PLN</h3>
              <h5>netto | m-c</h5>
              <div class="active">
                <i class="material-icons">done</i>
              </div>
            </div>
            <div class="box packet" :class="[getColorGradientClass(), { active: chosenPacket == 3 }]" @click="changePacket(3)">
              <h4>Pakiet Komfort</h4>
              <h3>{{ packetPrices[2] }} PLN</h3>
              <h5>netto | m-c</h5>
              <div class="active">
                <i class="material-icons">done</i>
              </div>
            </div>
          </div>
        </div>
        <div class="price">
          <h5>Koszt przedłużenia usługi</h5>
          <div :class="[getColorClass()]">
            <h4>Netto:</h4>
            <p>{{ getNetto() }} PLN</p>
          </div>
          <div :class="[getColorClass()]">
            <h4>Brutto:</h4>
            <p>{{ getBrutto() }} PLN</p>
          </div>
        </div>
        <div class="flex">
          <button class="default small reverse" :class="[getColorShadowClass()]" @click="close()">Anuluj</button>
          <button class="default small" :class="[getColorShadowClass()]" @click="addPeriod()">Przedłuż</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceExtend',
  data(){
    return{
      ready: 0,
      days: 30,
      date: '2020-01-08',
      month: 3,
      packetId: 0,
      prices: [0, 0, 0],
      packetVisibility: 0,
      packetsInfo: 0,
      packetPrices: [0, 0, 0],
      chosenPacket: 1,
    }
  },
  computed:{
    visible(){
      return this.$store.state.popup;
    },
    api() {
      return this.$store.state.api;
    },
  },
  created(){
    this.getServicesList();
  },
  methods: {
    close(){
      this.$store.commit('togglePopup', 0);
      document.body.style = "overflow-y: overlay";
    },
    getColorClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'color-orange';
        case 2:
          return 'color-blue';
        case 3:
          return 'color-red';
        case 4:
          return 'color-green';
        case 5:
          return 'color-pink';
        case 6:
          return 'color-purple';
      }
    },
    getColorGradientClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    getColorShadowClass(){
      switch(parseInt(localStorage.companyColor)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    formatDate(date){
      if(date == null){
        return 'Brak'        
      }
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays;
    },
    activateMonth(month){
      this.month = month;
      if(this.month == 3){
        this.packetPrices = [parseInt(this.packetsInfo[0].price_netto), parseInt(this.packetsInfo[3].price_netto), parseInt(this.packetsInfo[6].price_netto)]
      }else if(this.month == 6){
        this.packetPrices = [parseInt(this.packetsInfo[1].price_netto), parseInt(this.packetsInfo[4].price_netto), parseInt(this.packetsInfo[7].price_netto)]
      }else{
        this.packetPrices = [parseInt(this.packetsInfo[2].price_netto), parseInt(this.packetsInfo[5].price_netto), parseInt(this.packetsInfo[8].price_netto)]
      }
    },
    getNewDate(){
      let currentDate = new Date(this.date);
      let newDate = '';
      if(currentDate.getDate() == 1){
        newDate = currentDate.setMonth(currentDate.getMonth() + this.month);
      }else{
        newDate = currentDate.getTime() + (this.days * this.month * 86400000);
      }
      newDate = new Date(newDate)
      let chosenDays = newDate.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = newDate.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      return newDate.getFullYear() + '-' + chosenMonths + '-' + chosenDays;
    },
    getNetto(){
      switch(this.month){
        case 3:
          return (this.prices[0] * this.month).toFixed(2);
        break;
        case 6:
          return (this.prices[1] * this.month).toFixed(2);
        break;
        case 12:
          return (this.prices[2] * this.month).toFixed(2);
        break;
      }
    },
    getBrutto(){
      switch(this.month){
        case 3:
          return (this.prices[0] * this.month * 1.23).toFixed(2);
        break;
        case 6:
          return (this.prices[1] * this.month * 1.23).toFixed(2);
        break;
        case 12:
          return (this.prices[2] * this.month * 1.23).toFixed(2);
        break;
      }
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        for(let i = 0; i < response.data.data.length; i++){
          if(self.$route.name == 'Service1' && response.data.data[i].ServiceType.id == 1){
            self.date = response.data.data[i].end_at;
            self.packetId = response.data.data[i].Service.id;
          }
        }
        if(self.$route.name == 'Service1'){
          self.getVirtualOfficePacketsData();
        }
      });
    },
    getVirtualOfficePacketsData(){
      let self = this;
      this.axios
      .get(this.api + 'service/vo')
      .then(response => {
        // Periods data
        if(self.packetId <= 3){
          for(let i = 0; i < 3; i++){
            this.prices[i] = parseInt(response.data.data[i].price_netto);
          }
          this.chosenPacket = 1;
        }else if(self.packetId <= 6){
          for(let i = 3; i < 6; i++){
            this.prices[i - 3] = parseInt(response.data.data[i].price_netto);
          }
          this.chosenPacket = 2;
        }else{
          for(let i = 6; i < 9; i++){
            this.prices[i - 6] = parseInt(response.data.data[i].price_netto);
          }
          this.chosenPacket = 3;
        }
        this.packetPrices = [parseInt(response.data.data[0].price_netto), parseInt(response.data.data[3].price_netto), parseInt(response.data.data[6].price_netto)]
        this.packetsInfo = response.data.data;
        self.ready = 1;
      });
    },
    changePacketsVisibility(){
      if(this.packetVisibility){
        this.packetVisibility = 0;
        if(this.packetId <= 3){
          this.changePacket(1)
        }else if(this.packetId <= 6){
          this.changePacket(2)
        }else{
          this.changePacket(3)
        }
      }else{
        this.packetVisibility = 1;
      }
    },
    getTextChangePacketVisibility(){
      if(this.packetVisibility == 0){
        return 'Zmień pakiet';
      }else{
        return 'Nie zmieniaj pakietu';
      }
    },
    changePacket(packet){
      this.chosenPacket = packet;
      if(this.chosenPacket == 1){
        for(let i = 0; i < 3; i++){
          this.prices[i] = parseInt(this.packetsInfo[i].price_netto);
        }
      }else if(this.chosenPacket == 2){
        for(let i = 3; i < 6; i++){
          this.prices[i - 3] = parseInt(this.packetsInfo[i].price_netto);
        }
      }else{
        for(let i = 6; i < 9; i++){
          this.prices[i - 6] = parseInt(this.packetsInfo[i].price_netto);
        }
      }
    },
    addPeriod(){
      let period = 0;
      switch(this.month){
        case 3:
          period = 1;
        break;
        case 6:
          period = 2;
        break;
        case 12:
          period = 3;
        break;
      }
      let self = this;
      this.axios
      .put(this.api + 'service/virtualoffice',  { period: period, packet: this.chosenPacket, nip: localStorage.companyNip, }, 
        { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status == 0){
          self.$store.commit('toggleAlert', {
            visibility: 1,
            type: response.data.status,
            msg: response.data.msg
          });
        }else if(response.data.status == 1){
          // let win = window.open(response.data.href + response.data.token, '_blank');
          // win.focus();
          location.replace(response.data.href + response.data.token);
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div.popup div.wrapper { display: flex; flex-direction: column; align-items: center; }
  div.popup div.wrapper div.flex-around { display: flex; justify-content: space-around; width: 100%; }
  div.popup div.wrapper div.flex { display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%; }

  div.popup div.wrapper div.date { width: 220px; margin: 10px 0; }
  div.popup div.wrapper div.date header.box h6 { font-size: 11px; }
  div.popup div.wrapper div.date h4 { margin: 10px 0; padding: 0; text-align: center; font-weight: 400; font-size: 22px; }
  div.popup div.wrapper div.date:first-child h4 { color: $grayLighter; }

  div.popup div.wrapper div.month { display: flex; flex-direction: column; justify-content: center; width: 140px; height: 140px; margin: 10px 0; position: relative;
    cursor: pointer; }
  div.popup div.wrapper div.month h3 { margin: 0; padding: 0; font-weight: 400; text-align: center; color: rgba(255, 255, 255, 0.9); font-size: 38px; }
  div.popup div.wrapper div.month h5 { margin: 0; padding: 0; font-weight: 400; text-align: center; color: rgba(255, 255, 255, 0.8); font-size: 14px; }
  div.popup div.wrapper div.month div.active { display: none; flex-direction: column; justify-content: center; align-items: center; width: 140px; height: 140px; 
    position: absolute; top: 0; left: 0; background: rgba(255, 255, 255, 0.3); }
  div.popup div.wrapper div.month div.active i.material-icons { color: $grayLighter; font-size: 70px; }
  div.popup div.wrapper div.month.active div.active { display: flex; }

  div.popup div.wrapper div.warning { margin: 10px 0; padding: 10px; border-radius: 4px; background: $red; }
  div.popup div.wrapper div.warning h4 { margin: 0; padding: 0; font-weight: 500; font-size: 14px; color: #fff; }
  div.popup div.wrapper div.warning p { margin: 0; padding: 0; font-size: 13px; color: rgba(255, 255, 255, 0.9); }
  div.popup div.wrapper button.change { margin: 10px auto 10px 0; font-size: 11px; }

  div.popup div.wrapper div.packet { display: flex; flex-direction: column; justify-content: center; width: 140px; height: 140px; margin: 10px 0; border-radius: 2px;
    cursor: pointer; position: relative; }
  div.popup div.wrapper div.packet.gradient-gray { cursor: default; }
  div.popup div.wrapper div.packet h4 { margin: 0; padding: 0; font-weight: 400; text-align: center; color: rgba(255, 255, 255, 0.9); font-size: 15px; }
  div.popup div.wrapper div.packet h3 { margin: 10px 0; padding: 0; font-weight: 400; text-align: center; color: #fff; font-size: 20px; }
  div.popup div.wrapper div.packet h5 { margin: 0; padding: 0; font-weight: 400; text-align: center; color: rgba(255, 255, 255, 0.8); font-size: 11px; }
  div.popup div.wrapper div.packet h6 { width: 100%; margin: 0; padding: 0; position: absolute; bottom: 8px; font-weight: 400; font-size: 9px; 
    color: rgba(255, 255, 255, 0.8); text-align: center; }
  div.popup div.wrapper div.packet div.active { display: none; flex-direction: column; justify-content: center; align-items: center; width: 140px; height: 140px; 
    position: absolute; top: 0; left: 0; background: rgba(255, 255, 255, 0.3); }
  div.popup div.wrapper div.packet div.active i.material-icons { color: $grayLighter; font-size: 70px; }
  div.popup div.wrapper div.packet.active div.active { display: flex; }

  div.popup div.wrapper div.price { width: 100%; margin: 20px 0; }
  div.popup div.wrapper div.price h5 { margin: 0; padding: 0; font-weight: 400; text-align: right; font-size: 16px; color: $grayLight; }
  div.popup div.wrapper div.price div { display: flex; justify-content: space-between; width: 250px; height: 35px; margin: 10px 0 10px auto; border-radius: 2px; }
  div.popup div.wrapper div.price div.color-orange { background: rgb(245,87,39); }
  div.popup div.wrapper div.price div.color-blue { background: rgb(62,187,251); }
  div.popup div.wrapper div.price div.color-red { background:rgb(242,69,39); }
  div.popup div.wrapper div.price div.color-green { background: rgb(39,241,155); ; }
  div.popup div.wrapper div.price div.color-pink { background: rgb(193,63,242); }
  div.popup div.wrapper div.price div.color-purple { background: rgb(102,27,245); }
  div.popup div.wrapper div.price h4 { margin: 0 10px; padding: 0; font-weight: 400; text-align: right; color: rgba(255, 255, 255, 0.9); font-size: 14px; 
    line-height: 35px; }
  div.popup div.wrapper div.price p { margin: 0 10px; padding: 0; font-size: 18px; color: #fff; line-height: 35px; }

  div.popup div.wrapper button { margin: 15px 0 30px 0; }
</style>
