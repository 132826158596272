<template>
  <div class="orders" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="orders-list" v-if="loader == 0">
      <div class="box order" :class="[getColorClass()]" v-for="order in orders" :key="order.id" @click="showOrder(order.id)">
        <header>
          <div class="icon">
            <i class="material-icons">content_paste</i>
          </div>
          <h5># {{ order.id }}</h5>
        </header>
        <div class="middle">
          <div class="status">
            <h4>{{ getStatus(order.status) }}</h4>
            <h5>{{ (order.total_netto * 1.23).toFixed(2) }} PLN</h5>
          </div>
        </div>
        <h6>{{ formatDate(order.createdAt) }}</h6>
      </div>
    </div>
    <popup-order></popup-order>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import Loader from '@/components/Loader.vue'
import PopupOrder from '@/components/popups/Order.vue'

export default {
  name: 'Orders',
  components: {
    PanelNav,
    Loader,
    PopupOrder
  },
  data(){
    return{
      color: 0,
      loader: 1,
      orders: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    reloadView() {
      return this.$store.state.reloadView;
    },
  },
  beforeRouteEnter (to, from, next) {
    if (((typeof(localStorage.accessToken) === "undefined" || typeof(localStorage.refreshToken) === "undefined")) || typeof(localStorage.accountStatus) !== "undefined" ) {
      next(false);
    }else{
      next();
    }
  },
  created(){
    this.color = localStorage.companyColor;
    this.getOrdersList();
  },
  watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
      this.reload();
    },
    reloadView(){
      this.getOrdersList();
    }
  },
  methods: {
    reload(){
      this.loader = 1;
      this.getOrdersList();
    },
    showOrder(id){
      this.$store.commit('togglePopup', 1);
      this.$store.commit('changeOrderId', id);
    },
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    formatDate(date){
      if(date == null){
        return 'Brak'        
      }
      let dateObj = new Date(date);
      let chosenDays = dateObj.getDate();
      if(chosenDays < 10){
        chosenDays = '0' + chosenDays;
      }
      let chosenMonths = dateObj.getMonth() + 1;
      if(chosenMonths < 10){
        chosenMonths = '0' + chosenMonths
      }
      let chosenHours = dateObj.getHours();
      if(chosenHours < 10){
        chosenHours = '0' + chosenHours;
      }
      let chosenMinutes = dateObj.getMinutes();
      if(chosenMinutes < 10){
        chosenMinutes = '0' + chosenMinutes;
      }
      return dateObj.getFullYear() + '-' + chosenMonths + '-' + chosenDays + ' ' + chosenHours + ':' + chosenMinutes;
    },
    getStatus(status){
      switch(parseInt(status)){
        case 0:
          return 'Nieopłacone';
        break;
        case 1:
          return 'Opłacone';
        break;
        case 2:
          return 'Anulowane';
        break;
      }
    },
    getOrdersList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/orders', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.orders = response.data.data;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.orders.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px); }
  div.orders-list { display: flex; flex-wrap: wrap; margin: 0 auto; padding: 10px; width: 1780px; }

  div.order { display: flex; flex-direction: column; justify-content: space-between; width: 200px; height: 175px; cursor: pointer; }
  div.order header { display: flex; justify-content: space-between; opacity: 0.8; }
  div.order header div.icon { display: flex; justify-content: center; align-items: center; width: 21px; height: 21px; margin: 10px; }
  div.order header div.icon i.material-icons { color: #fff; font-size: 21px; }
  div.order header h5 { margin: 10px; padding: 0; font-weight: 300; text-align: center; font-size: 16px; color: #fff; }
  div.order div.middle { display: flex; justify-content: center; }
  div.order div.middle div.status { display: flex; flex-direction: column; justify-content: center; align-items: center; }
  div.order div.middle div.status h4 { margin: 0; padding: 0; font-weight: 300; font-size: 22px; color: #fff; }
  div.order div.middle div.status h4 span { font-weight: 400; font-size: 19px; }
  div.order div.middle div.status h5 { margin: 3px 0 0 0; padding: 0; font-weight: 300; font-size: 13px; color: rgba(255, 255, 255, 0.6); }
  div.order h6 { width: 100%; height: 30px; margin: 13px 0 0 0; padding: 0; font-weight: 400; font-size: 12px; color: #fff; text-align: center; background: rgba(255, 255, 255, 0.1);
    line-height: 30px; }

  @media (max-width: 1790px) {
    div.orders-list { width: 1580px; }
  }
  @media (max-width: 1590px) {
    div.orders-list { width: 1370px; }
  }
  @media (max-width: 1380px) {
    div.orders-list { width: 1140px; }
  }
  @media (max-width: 1150px) {
    div.orders-list { width: 920px; }
  }
  @media (min-width: 1000px) {
    div#panel.element-menu div.orders { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.orders-list { width: 100%; }
  }
  @media (max-width: 930px) {
    div.orders-list { width: 680px; }
  }
  @media (max-width: 700px) {
    div.orders-list { width: 460px; }
  }
  @media (max-width: 500px) {
    div.orders-list { width: 360px; }
    div.order { width: 150px; height: 125px; }
    div.order header div.icon { margin: 5px; width: 25px; height: 25px; }
    div.order header div.icon i.material-icons { font-size: 18px; }
    div.order header h5 { margin: 5px; font-size: 14px; line-height: 28px; }
    div.order div.middle div.status h4 {  font-size: 16px; }
    div.order div.middle div.status h4 span { font-size: 14px; }
    div.order div.middle div.status h5 { margin: 0; font-size: 10px; }
    div.order h6 { height: 25px; margin: 13px 0 0 0; font-size: 10px; line-height: 25px; }
  }
  @media (max-width: 220px) {
    div.invoices-list { width: 100%; }
  }
</style>
