<template>
  <header :class="[getColorClass()]">
    <div class="menu" v-if="menu" @click="showMenu()">
      <i class="material-icons">menu</i>
    </div>
    <h1><router-link to="/firmy">AFK Panel Klienta</router-link></h1>
    <h2 v-if="activeRoute != 'Companies' && activeRoute != 'Settings' && activeRoute != 'Help'">{{ companyName }}</h2>
    <nav>
      <ul>
        <li title="Firmy" :class="{ active: activeRoute == 'Companies' }">
          <router-link to="/firmy"><i class="material-icons">apps</i></router-link>
        </li>
        <li title="Powiadomienia" :class="{ active: elementNotifications == 1 }" @click="toggleNotifications()">
          <div class="new" :class="[getColorShadowClass()]" v-if="notifications"></div>
          <i class="material-icons">notifications</i>
        </li>
        <li title="Ustawienia" :class="{ active: activeRoute == 'Settings' }">
          <router-link to="/ustawienia"><i class="material-icons">settings</i></router-link>
        </li>
        <li title="Pomoc" :class="{ active: activeRoute == 'Help' }">
          <router-link to="/pomoc"><i class="material-icons">help</i></router-link>
        </li>
        <li title="Wyloguj" @click="logoutRequest()">
          <i class="material-icons">exit_to_app</i>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data(){
    return{
      activeRoute: '',
      menu: 0,
      companyName: '',
      notifications: 0,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    elementNotifications(){
      return this.$store.state.elementNotifications;
    },
    elementMenu(){
      return this.$store.state.elementMenu;
    },
    companyChange(){
      return this.$store.state.companyChange;
    },
  },
  created(){
    this.companyName = localStorage.companyName;
    this.activeRoute = this.$route.name;
    this.showMenuIcon();
    this.getNotifications();
  },
  watch: {
    $route(to, from) {
      this.companyName = localStorage.companyName;
      this.activeRoute = this.$route.name;
      this.showMenuIcon();
    },
    companyChange(){
      if(this.companyChange == 1){
        this.companyName = localStorage.companyName;
        this.$store.commit('toggleCompanyChange', 0);
      }
    },
    elementNotifications(){
      if(this.elementNotifications == 0){
        this.getNotifications();
      }
    }
  },
  methods: {
    showMenuIcon(){
      if(this.$route.name != 'Companies' && this.$route.name != 'Settings' && this.$route.name != 'Help'){
        this.menu = 1;
      }else{
        this.menu = 0;
      }
    },
    showMenu(){
      this.$store.commit('toggleMenu', 1);
    },
    toggleNotifications(){
      if(this.elementNotifications == 1){
        this.$store.commit('toggleNotifications', 0);
      }else{
        this.$store.commit('toggleNotifications', 1);
      }
    },
    logoutRequest(){
      let self = this;
      this.axios
      .put(this.api + 'auth/logout', {}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
      }).then(function () {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem('companyNip');
        localStorage.removeItem('companyName');
        localStorage.removeItem('companyColor');
        self.$store.commit('toggleMenu', 0);
        localStorage.elementMenu = 0;
        self.$router.push('/')
      });
    },
    getColorClass(){
      if(typeof localStorage.companyColor == 'undefined'){
        return 'orange';
      }else if(this.$route.name == 'Settings'){
        return 'orange';
      }else{
        switch(parseInt(localStorage.companyColor)){
          case 1:
            return 'orange';
          case 2:
            return 'blue';
          case 3:
            return 'red';
          case 4:
            return 'green';
          case 5:
            return 'pink';
          case 6:
            return 'purple';
        }
      }
    },
    getColorShadowClass(){
      if(typeof localStorage.companyColor == 'undefined'){
        return 'gradient-orange-shadow';
      }else if(this.$route.name == 'Settings'){
        return 'gradient-orange-shadow';
      }else{
        switch(parseInt(localStorage.companyColor)){
          case 1:
            return 'gradient-orange-shadow';
          case 2:
            return 'gradient-blue-shadow';
          case 3:
            return 'gradient-red-shadow';
          case 4:
            return 'gradient-green-shadow';
          case 5:
            return 'gradient-pink-shadow';
          case 6:
            return 'gradient-purple-shadow';
        }
      }
    },
    getNotifications(){
      let self = this;
      this.axios
      .get(this.api + 'panel/notifications', { params: { }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.newCount > 0){
          self.notifications = 1;
        }else{
          self.notifications = 0;
        }
      });
    },
  }
}
</script>
<style scoped lang="scss">
  header { display: flex; width: 100%; height: 60px; background: $grayLighter5; border-bottom: 1px solid #EEEEEE; box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3); }
  header div.menu { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 50px; height: 50px; margin: 5px; border-radius: 3px; cursor: pointer; }
  header div.menu i.material-icons { font-size: 23px; color: $grayLight; }
  header h1 { margin: 0; padding: 0; text-indent: -9999px; }
  header h1 a { display: block; width: 100px; height: 60px; background: url('../assets/logo_30_normal.png') no-repeat 50% 50%; text-decoration: none; }
  header h2 { margin: 0 8px 0 0 ; padding: 0; font-weight: 400; color: $grayLight; line-height: 60px; font-size: 18px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
  header nav { margin-left: auto; }
  header nav ul { display: flex; align-items: center; height: 60px; margin: 0; padding: 0; list-style: none; }
  header nav ul li { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; margin: 0 10px; position: relative; border-radius: 3px; }
  header nav ul li a { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; text-decoration: none; }
  header nav ul li i.material-icons { font-size: 23px; color: $grayLight; }
  header nav ul li div.new { width: 8px; height: 8px; border-radius: 50%; position: absolute; top: 8px; left: 34px; }
  header nav ul li div.new.gradient-orange-shadow { box-shadow: 0px 0px 2px rgb(242,69,39) }
  header nav ul li div.new.gradient-blue-shadow { box-shadow: 0px 0px 2px rgb(62,187,251); }

  header.orange nav ul li.active { background: $orangeLight; background: linear-gradient(135deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%); }
  header nav ul li.active i.material-icons { color: #fff; }
  header nav ul li.active div.new { background: #fff; box-shadow: 0px 0px 5px #fff; }

  header div.menu:hover i.material-icons { color: #fff; }
  header div.menu:hover { background: $orangeLight; background: linear-gradient(135deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%); }
  header nav ul li:hover { background: $orangeLight; background: linear-gradient(135deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%); cursor: pointer; }
  header nav ul li:hover i.material-icons { color: #fff; }
  header nav ul li:hover div.new { background: #fff; }

  header.orange nav ul li.active, header.orange nav ul li:hover, header.orange div.menu:hover { background: rgb(245,87,39); background: linear-gradient(45deg, rgba(245,87,39,1) 0%, rgba(251,210,70,1) 100%); }
  header.blue nav ul li.active, header.blue nav ul li:hover, header.blue div.menu:hover { background: rgb(62,187,251); background: linear-gradient(45deg, rgba(62,187,251,1) 0%, rgba(31,140,243,1) 100%); }
  header.red nav ul li.active, header.red nav ul li:hover, header.red div.menu:hover { background: rgb(242,69,39); background: linear-gradient(45deg, rgba(242,69,39,1) 0%, rgba(222,38,103,1) 100%); }
  header.green nav ul li.active, header.green nav ul li:hover, header.green div.menu:hover { background: rgb(39,241,155); background: linear-gradient(45deg, rgba(39,241,155,1) 0%, rgba(15,181,221,1) 100%); }
  header.pink nav ul li.active, header.pink nav ul li:hover, header.pink div.menu:hover { background: rgb(193,63,242); background: linear-gradient(45deg, rgba(193,63,242,1) 0%, rgba(222,38,103,1) 100%); }
  header.purple nav ul li.active, header.purple nav ul li:hover, header.purple div.menu:hover { background: rgb(102,27,245); background: linear-gradient(45deg, rgba(102,27,245,1) 0%, rgba(70,94,250,1) 100%); }

  header nav ul li div.new.gradient-orange-shadow { box-shadow: 0px 0px 2px #fff }
  header nav ul li div.new.gradient-blue-shadow { box-shadow: 0px 0px 2px #fff }
  header nav ul li div.new.gradient-red-shadow { box-shadow: 0px 0px 2px #fff }
  header nav ul li div.new.gradient-green-shadow { box-shadow: 0px 0px 2px #fff }
  header nav ul li div.new.gradient-pink-shadow { box-shadow: 0px 0px 2px #fff }
  header nav ul li div.new.gradient-purple-shadow { box-shadow: 0px 0px 2px #fff }

  @media (min-width: 1000px) {
    div#panel.element-menu header h2 { margin-left: 100px; }
  }

  @media (max-width: 450px) {
    header h1 { display: none; }
    header h2 { margin: 0 8px; } 
    header nav ul li { margin: 0 5px; }
  }

  @media (max-width: 380px) {
    header h2 { display: none; }
  }
  @media (max-width: 360px) {
    header div.menu { width: 40px; height: 40px; margin: 10px 5px; }
    header nav ul li { width: 40px; height: 40px; }
    header nav ul li a { width: 40px; height: 40px; }
  }
  @media (max-width: 360px) {
    header div.menu { width: 40px; height: 40px; margin: 10px 5px; }
    header nav ul li { width: 40px; height: 40px; }
    header nav ul li a { width: 40px; height: 40px; }
  }
  @media (max-width: 320px) {
    header div.menu { width: 35px; height: 35px; margin: 12.5px 5px; }
    header nav ul li { width: 35px; height: 35px; margin: 0 5px; }
    header nav ul li a { width: 35px; height: 35px; }
  }
</style>
