<template>
  <div class="company" :class="{ center: loader  == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="company-info" v-if="loader == 0">
      <div class="flex">
        <div>
          <header class="panel">
            <h6>Informacje o firmie</h6>
          </header>
          <div class="flex-column">
            <div class="box big">
              <header class="box">
                <h6>pełna nazwa i typ działalności</h6>
              </header>
              <h3 :class="[getColorClass()]">{{ company.name }}</h3>
              <h4>{{ company.type }}</h4>
            </div>
            <div class="flex">
              <div class="box one">
                <h4>{{ company.nip }}</h4>
                <h5>NIP</h5>
              </div>
              <div class="box one">
                <h4>{{ company.regon }}</h4>
                <h5>REGON</h5>
              </div>
              <div class="box gus" :class="[getColorGradientClass()]" @click="updateGus()">
                <h3>Aktualizuj</h3>
                <h4>Dane z GUS</h4>
              </div>
            </div>
          </div>
        </div>
        <div>
          <header class="panel">
            <h6>Dane do korespondecji</h6>
          </header>
          <div class="flex">
            <div class="box address-data" :class="[getColorGradientClass(), { big: correspondenceInfo.comment != '' }]" v-if="correspondenceInfo.length != 0" 
            @click="showPopupCorrAddress(13, correspondenceInfo.city, correspondenceInfo.street, correspondenceInfo.number_house, correspondenceInfo.post_code, correspondenceInfo.comment )">
              <div class="flex">
                <div class="icon">
                  <i class="material-icons">place</i>
                </div>
                <ul v-if="correspondenceInfo.length != 0">
                  <li>
                    <p>Miasto:</p>
                    <p>{{ correspondenceInfo.city }}</p>
                  </li>
                  <li>
                    <p>Ulica:</p>
                    <p>{{ correspondenceInfo.street }}</p>
                  </li>
                  <li>
                    <p>Dom/Lokal:</p>
                    <p>{{ correspondenceInfo.number_house }}</p>
                  </li>
                  <li>
                    <p>Kod pocztowy:</p>
                    <p>{{ correspondenceInfo.post_code }}</p>
                  </li>
                </ul>
              </div>
              <div class="flex" v-if="correspondenceInfo.comment != ''">
                <div class="icon">
                  <i class="material-icons">info</i>
                </div>
                <div>
                  <h6>Dodatkowe uwagi:</h6>
                  <p>{{ correspondenceInfo.comment }}</p>
                </div>
              </div>
            </div>
            <div class="box add address-data" :class="[getColorGradientClass()]" @click="showPopupCorrAddress(8)" v-if="correspondenceInfo.length == 0">
              <h5>Dodaj adres</h5>
              <i class="material-icons">add</i>
            </div>
            <div class="phone_numbers">
              <div class="box address" :class="[getColorGradientClass()]" @click="showPopupCorrPhone(10, phone.phone)" v-for="phone in correspondencePhones" :key="phone.phone">
                <p>{{ phone.phone }}</p>
              </div>
              <div class="box add address" :class="[getColorGradientClass()]" @click="showPopupCorrPhone(9, 0)">
                <h5>Dodaj nowy telefon</h5>
                <i class="material-icons">add</i>
              </div>
            </div>
            <div class="emails">
              <div class="box address" :class="[getColorGradientClass()]" @click="showPopupCorrEmail(12, email.email)" v-for="email in correspondenceEmails" :key="email.email">
                <p>{{ email.email }}</p>
              </div>
              <div class="box add address" :class="[getColorGradientClass()]" @click="showPopupCorrEmail(11, 0)">
                <h5>Dodaj nowy email</h5>
                <i class="material-icons">add</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-persons-contract" v-if="loader == 0">
      <header class="panel">
        <h6>Osoby upoważnione do podpisania umowy</h6>
        <div class="icon" :class="[getColorClass()]" title="Infomacje dotyczące podawanych osób" @click="showPopupPersonContractInfo()">
          <i class="material-icons">info</i>
        </div>
      </header>
      <div class="flex">
        <div class="box person" :class="[getColorGradientClass()]" @click="showPopupPersonContract(2, person.name, person.surname, person.identity_card, person.position, person.identity_card_type, person.id)"
        v-for="person in contractSigners" :key="person.id">
          <div class="icon">
            <i class="material-icons">person</i>
          </div>
          <div class="info">
            <h5>{{ person.name }} {{ person.surname }}</h5>
            <!-- <h6><span>{{ getIdentityCardText(person.identity_card_type) }}: </span>{{ person.identity_card }}</h6> -->
            <h6><span>Stanowisko: </span>{{ person.position }}</h6>
          </div>
        </div>
        <div class="box add person" title="Dodaj nową osobę" :class="[getColorGradientClass()]" @click="showPopupPersonContract(1)">
          <h5>Dodaj nową osobę</h5>
          <i class="material-icons">add</i>
        </div>
      </div>
    </div>
    <div class="company-persons-post" v-if="loader == 0">
      <header class="panel">
        <h6>Osoby upoważnione do odbioru korespondecji</h6>
        <div class="icon" :class="[getColorClass()]" title="Infomacje dotyczące podawanych osób" @click="showPopupPersonPostInfo()">
          <i class="material-icons">info</i>
        </div>
      </header>
      <div class="flex">
        <div class="box person" :class="[getColorGradientClass()]" @click="showPopupPersonPost(5, person.name, person.surname, person.identity_card, person.identity_card_type)" 
          v-for="person in correspondencePeople" :key="person.identity_card">
          <div class="icon">
            <i class="material-icons">person</i>
          </div>
          <div class="info">
            <h5>{{ person.name }} {{ person.surname }}</h5>
            <h6><span>{{ getIdentityCardText(person.identity_card_type) }}: </span>{{ person.identity_card }}</h6>
          </div>
        </div>
        <div class="box add person" :class="[getColorGradientClass()]" @click="showPopupPersonPost(4, 0)">
          <h5>Dodaj nową osobę</h5>
          <i class="material-icons">add</i>
        </div>
      </div>
    </div>
    <div class="company-delete" v-if="loader == 0">
      <header class="panel">
        <h6>Usuwanie firmy</h6>
      </header>
      <button class="default" :class="[getColorShadowClass()]" @click="showPopupCompanyDelete()">Usuń firmę</button>
    </div>
    <popup-company-persons-contract></popup-company-persons-contract>
    <popup-company-persons-contract-info></popup-company-persons-contract-info>
    <popup-company-persons-post></popup-company-persons-post>
    <popup-company-persons-post-info></popup-company-persons-post-info>
    <popup-company-delete></popup-company-delete>
    <popup-company-corr-address></popup-company-corr-address>
    <popup-company-corr-phone></popup-company-corr-phone>
    <popup-company-corr-email></popup-company-corr-email>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import PanelNav from '@/components/PanelNav.vue'
import popupCompanyPersonsContract from '@/components/popups/CompanyPersonsContract.vue'
import popupCompanyPersonsContractInfo from '@/components/popups/CompanyPersonsContractInfo.vue'
import popupCompanyPersonsPost from '@/components/popups/CompanyPersonsPost.vue'
import popupCompanyPersonsPostInfo from '@/components/popups/CompanyPersonsPostInfo.vue'
import popupCompanyDelete from '@/components/popups/CompanyDelete.vue'
import popupCompanyCorrAddress from '@/components/popups/CompanyCorrAddress.vue'
import popupCompanyCorrPhone from '@/components/popups/CompanyCorrPhone.vue'
import popupCompanyCorrEmail from '@/components/popups/CompanyCorrEmail.vue'

export default {
  name: 'Company',
  components: {
    Loader,
    PanelNav,
    popupCompanyPersonsContract,
    popupCompanyPersonsContractInfo,
    popupCompanyPersonsPost,
    popupCompanyPersonsPostInfo,
    popupCompanyDelete,
    popupCompanyCorrAddress,
    popupCompanyCorrPhone,
    popupCompanyCorrEmail,
  },
  data(){
    return{
      loader: 1,
      company: 0,
      contractSigners: 0,
      correspondenceInfo: 0,
      correspondenceEmails: 0,
      correspondencePhones: 0,
      correspondencePeople: 0,
    }
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
    companyEdit() {
      return this.$store.state.companyEdit;
    },
  },
  created(){
    this.color = localStorage.companyColor;
    this.getCompanyData();
  },
  watch: {
    $route(to, from) {
      this.color = localStorage.companyColor;
      this.reload();
    },
    companyEdit(){
      this.getCompanyData();
    }
  },
  methods: {
    reload(){
      this.loader = 1;
      this.getCompanyData();
    }, 
    showPopupPersonContract(type, name, surname, identity_card, position, identity_card_type, id){
      this.$store.commit('changeCompanyInfoEdit', { name: name, surname: surname, identity_card: identity_card, position: position, identity_card_type: identity_card_type, id: id });
      this.$store.commit('togglePopup', type);
      document.body.style = "overflow-y: hidden";
    },
    showPopupPersonContractInfo(){
      this.$store.commit('togglePopup', 3);
      document.body.style = "overflow-y: hidden";
    },
    showPopupPersonPost(type, name, surname, identity_card, identity_card_type){
      this.$store.commit('changeCompanyInfoEdit', { name: name, surname: surname, identity_card: identity_card, identity_card_type: identity_card_type });
      this.$store.commit('togglePopup', type);
      document.body.style = "overflow-y: hidden";
    },
    showPopupPersonPostInfo(){
      this.$store.commit('togglePopup', 6);
      document.body.style = "overflow-y: hidden";
    },
    showPopupCompanyDelete(){
      this.$store.commit('togglePopup', 7);
      document.body.style = "overflow-y: hidden";
    },
    showPopupCorrAddress(type, city, street, houseNumber, postCode, comment){
      this.$store.commit('changeCompanyInfoEdit', { city: city, street: street, houseNumber: houseNumber, postCode: postCode, comment: comment });
      this.$store.commit('togglePopup', type);
      document.body.style = "overflow-y: hidden";
    },
    showPopupCorrPhone(type, data){
      this.$store.commit('changeCompanyInfoEdit', data);
      this.$store.commit('togglePopup', type);
      document.body.style = "overflow-y: hidden";
    },
    showPopupCorrEmail(type, data){
      this.$store.commit('changeCompanyInfoEdit', data);
      this.$store.commit('togglePopup', type);
      document.body.style = "overflow-y: hidden";
    },
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'color-orange';
        case 2:
          return 'color-blue';
        case 3:
          return 'color-red';
        case 4:
          return 'color-green';
        case 5:
          return 'color-pink';
        case 6:
          return 'color-purple';
      }
    },
    getColorGradientClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
      }
    },
    getColorShadowClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange-shadow';
        case 2:
          return 'gradient-blue-shadow';
        case 3:
          return 'gradient-red-shadow';
        case 4:
          return 'gradient-green-shadow';
        case 5:
          return 'gradient-pink-shadow';
        case 6:
          return 'gradient-purple-shadow';
      }
    },
    getIdentityCardText(type){
      if(type == 1){
        return 'Pesel';
      }else{
        return 'Paszport'
      }
    },
    getCompanyData(){
      let self = this;
      this.axios
      .get(this.api + 'panel/data', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.company = response.data.data.company;
        self.contractSigners = response.data.data.contractSigners;
        self.correspondenceInfo = response.data.data.correspondenceInfo;
        self.correspondenceEmails = response.data.data.correspondenceEmails;
        self.correspondencePhones = response.data.data.correspondencePhones;
        self.correspondencePeople = response.data.data.correspondencePeople;
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
    updateGus(){
      this.$store.commit('toggleAlert', {
        visibility: 1,
        type: 2,
        msg: 'Trwa aktualizowanie... Proszę czekać'
      });
      let self = this;
      this.axios
      .put(this.api + 'panel/gus',  { nip: localStorage.companyNip, }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.$store.commit('toggleAlert', {
          visibility: 1,
          type: response.data.status,
          msg: response.data.msg
        });
        if(response.data.status){
          self.getCompanyData();
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
  div.company.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px);  }
  div.company-info, div.company-persons-contract, div.company-persons-post, div.company-delete { width: 1480px; margin: 0 auto; }

  div.flex { display: flex; flex-wrap: wrap; }
  div.flex-column { display: flex; flex-direction: column; flex-wrap: wrap; }
  div.flex-between { display: flex; justify-content: space-between; flex-wrap: wrap; }

  div.one { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 180px; height: 180px; }
  div.one h4 { margin: 0; padding: 0; font-weight: 400; color: $grayLighter; font-size: 24px; }
  div.one h5 { margin: 0; padding: 0; font-weight: 300; color: $grayLighter2; font-size: 18px; }

  div.gus { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 180px; height: 180px; cursor: pointer; }
  div.gus h3 { margin: 0; padding: 0; color:  rgba(255, 255, 255, 0.9); font-weight: 300; font-size: 22px; }
  div.gus h4 { margin: 0; padding: 0; font-weight: 300; color: rgba(255, 255, 255, 0.5); text-align: center; font-size: 16px; }

  div.big { display: flex; flex-direction: column; width: 580px; min-height: 180px; margin: 10px 10px 10px 10px; }
  div.big div.flex-between { margin-top: auto; }
  div.big h3 { margin: 5px 10px 15px 10px; padding: 0; font-weight: 500; font-size: 20px; }
  div.big h4 { margin: 5px 10px 15px 10px; padding: 0; color: $grayLight; font-weight: 400; font-size: 20px; }

  div.box.address-data { display: flex; flex-direction: column; justify-content: center; width: 320px; height: 180px; cursor: pointer; }
  div.box.address-data.big { padding: 25px 0; height: 380px; justify-content: flex-start; }
  div.box.address-data div.icon { display: flex; justify-content: center; align-items: center; width: 100px; height: 100px; margin: 10px 0; }
  div.box.address-data.big div.icon { align-items: flex-start; }
  div.box.address-data div.icon i.material-icons { font-size: 45px; color: rgba(255, 255, 255, 0.4); }
  div.box.address-data ul { display: flex; flex-wrap: wrap; width: 220px; margin: 0; padding: 5px 5px 5px 0; list-style: none; }
  div.box.address-data ul li { display: flex; flex-wrap: wrap; margin: 3px 0; }
  div.box.address-data ul li p { margin: 0; padding: 0; font-size: 15px; font-weight: 400; color: rgba(255, 255, 255, 0.9); }
  div.box.address-data ul li p:first-child { margin-right: 5px; color: rgba(255, 255, 255, 0.4); }
  div.box.address-data div h6 { margin: 10px 5px 5px 0; padding: 0; font-size: 15px; color: rgba(255, 255, 255, 0.4); font-weight: 400; }
  div.box.address-data div p { margin: 5px 5px 5px 0; padding: 0; color: rgba(255, 255, 255, 0.9); font-size: 15px; }
  div.box.add.address-data { flex-direction: column; justify-content: center; align-items: center; background: #F5F5F5; border: 2px solid $grayLighter2; 
    box-shadow: 0px 0px 1px $grayLighter3; }
  div.box.add.address-data h5 { margin: 3px 0; padding: 0; color: $grayLighter2; font-weight: 300; font-size: 20px; }
  div.box.add.address-data i.material-icons { margin: 3px 0; font-size: 20px; color: $grayLighter2; }
  div.box.add.address-data:hover { border-color: rgb(245,87,39); }
  div.box.add.address-data:hover h5 { color: rgb(245,87,39); }
  div.box.add.address-data:hover i.material-icons { color: rgb(245,87,39); }

  div.box.address { display: flex; justify-content: center; align-items: center; width: 250px; height: 60px; cursor: pointer; }
  div.box.address p { margin: 0; padding: 0 15px; color: rgba(255, 255, 255, 0.9); font-weight: 300; font-size: 17px; white-space: nowrap; 
    overflow: hidden; text-overflow: ellipsis; }

  div.box.add.address { flex-direction: column; background: #F5F5F5; border: 2px solid $grayLighter2; 
    box-shadow: 0px 0px 1px $grayLighter3; }
  div.box.add.address h5 { margin: 1px 0; padding: 0; color: $grayLighter2; font-weight: 300; font-size: 14px; }
  div.box.add.address i.material-icons { margin: 1px 0; font-size: 18px; color: $grayLighter2; }

  div.box.person { display: flex; width: 350px; height: 100px; cursor: pointer; }
  div.box.person div.icon { display: flex; justify-content: center; align-items: center; width: 100px; height: 100px; }
  div.box.person div.icon i.material-icons { font-size: 45px; color: rgba(255, 255, 255, 0.4); }
  div.box.person div.info { display: flex; flex-direction: column; justify-content: center; }
  div.box.person h5 { margin: 0 0 5px 0; padding: 0; font-size: 17px; font-weight: 600; color: #fff;  }
  div.box.person h6 { margin: 0; padding: 0; font-size: 13px; font-weight: 400; color: rgba(255, 255, 255, 0.9);  }
  div.box.person h6 span { color: rgba(255, 255, 255, 0.4); }
  div.box.person:hover div.icon i.material-icons { color: #fff; }
  div.box.person:hover h5 { color: #fff; };
  div.box.person:hover h6 { color: #fff; };
  div.box.person:hover h6 span { color: rgba(255, 255, 255, 0.3) }

  div.box.add.person { flex-direction: column; justify-content: center; align-items: center; background: #F5F5F5; border: 2px solid $grayLighter2; 
    box-shadow: 0px 0px 1px $grayLighter3; }
  div.box.add.person h5 { margin: 3px 0; padding: 0; color: $grayLighter2; font-weight: 300; font-size: 20px; }
  div.box.add.person i.material-icons { margin: 3px 0; font-size: 20px; color: $grayLighter2; }

  div.box.add.person:hover { border-color: rgb(245,87,39); }
  div.box.add.person:hover h5 { color: rgb(245,87,39); }
  div.box.add.person:hover i.material-icons { color: rgb(245,87,39); }
  div.box.add.gradient-orange:hover { background: none; border-color: rgb(245,87,39); }
  div.box.add.gradient-orange:hover h5, div.box.add.gradient-orange:hover i.material-icons  { background: none; color: rgb(245,87,39); }
  div.box.add.gradient-blue:hover { background: none; border-color: rgb(62,187,251); }
  div.box.add.gradient-blue:hover h5, div.box.add.gradient-blue:hover i.material-icons  { background: none; color: rgb(62,187,251); }
  div.box.add.gradient-red:hover { background: none; border-color: rgb(242,69,39); }
  div.box.add.gradient-red:hover h5, div.box.add.gradient-red:hover i.material-icons  { background: none; color: rgb(242,69,39);}
  div.box.add.gradient-green:hover { background: none; border-color: rgb(39,241,155); }
  div.box.add.gradient-green:hover h5, div.box.add.gradient-green:hover i.material-icons  { background: none; color: rgb(39,241,155); }
  div.box.add.gradient-pink:hover { background: none; border-color: rgb(193,63,242); }
  div.box.add.gradient-pink:hover h5, div.box.add.gradient-pink:hover i.material-icons  { background: none; color: rgb(193,63,242); }
  div.box.add.gradient-purple:hover { background: none; border-color: rgb(102,27,245) }
  div.box.add.gradient-purple:hover h5, div.box.add.gradient-purple:hover i.material-icons  { background: none; color: rgb(102,27,245) }
  
  div.company-delete button { display: block; margin: 0 auto; }

  @media (max-width: 1480px) {
    div.company-info, div.company-persons-contract, div.company-persons-post, div.company-delete { width: 1110px; }
    div.box.address-data { height: initial; }
    div.box.address-data button { margin: 20px auto 10px auto; }
  }
  @media (max-width: 1120px) {
    div.company-info, div.company-persons-contract, div.company-persons-post, div.company-delete { width: 740px; }
  }
  @media (min-width: 1000px) {
    div#panel.element-menu div.company { width: calc(100% - 250px); margin-left: 250px; }
    div#panel.element-menu div.company-info, div#panel.element-menu div.company-persons-contract, div#panel.element-menu div.company-persons-post, div#panel.element-menu div.company-delete { width: 100%; }
  }
  @media (max-width: 760px) {
    div.big { width: calc(100vw - 40px); }
    div.company-info, div.company-persons-contract, div.company-persons-post, div.company-delete { width: calc(100vw - 20px); }
    div.company-info div.flex { justify-content: center; }
    div.box.person { margin: 10px auto; }
  }
  @media (max-width: 500px) {
    div.big h3 { margin: 5px 10px 15px 10px; font-size: 16px; }
    div.big h4 { margin: 5px 10px 15px 10px; font-size: 16px; }

    div.one { width: 120px; height: 120px; }
    div.one h4 { font-size: 16px; }
    div.one h5 { font-size: 14px; }

    div.gus { width: 120px; height: 120px; }
    div.gus h3 { font-size: 16px; }
    div.gus h4 { font-size: 12px; }
  }

  @media (max-width: 350px) {
     div.box.person { width: calc(100vw - 20px); }
  }

</style>
