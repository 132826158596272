<template>
  <div class="virtual-office" :class="{ center: loader == 1 }">
    <loader v-if="loader == 1"></loader>
    <panel-nav v-if="loader == 0"></panel-nav>
    <div class="wrapper" :class="{ center: (empty == 1 && reservation == 0 && loader == 0)}">
      <div class="empty" v-if="reservation == 0 && loader == 0">
        <div class="img"></div>
        <h4>Prace w toku</h4>
        <h5>Funckjanolności będą sukcesywnie dodawane</h5>
      </div>
      <div class="box reservation" v-if="reservation && loader == 0">
        <header class="box">
          <h6>Rezerwacja sali</h6>
        </header>
        <div>
          <div class="img"></div>
          <h4>Ta funckjanolność jeszcze nie jest dostępna</h4>
          <h5>Aby zamówić rezerwację sali proszę zadzwonić do Biura Obsługi Klienta</h5>
          <h6>+48 71 336 95 21</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelNav from '@/components/PanelNav.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'VirtualOffice',
  components: {
    Loader,
    PanelNav,
  },
  data(){
    return{
      loader: 1,
      empty: 1,
      services: 0,
      reservation: 0,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
    loaderTime() {
      return this.$store.state.loaderTime;
    },
  },
  created(){
    this.getServicesList();
  },
  watch:{
    $route(to, from){
      this.reload();
    }
  },
  methods: {
    reload(){
      this.loader = 1;
      this.getServicesList();
    },
    getServicesList(){
      let self = this;
      this.axios
      .get(this.api + 'panel/services', { params: { nip: localStorage.companyNip }, headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        self.services = response.data.data;
        self.checkReservation(self.services);
        setTimeout(function(){ self.loader = 0; }, self.loaderTime);
      });
    },
    checkReservation(services){
      for(let i = 0; i < services.length; i++){
        if(services[i].service_name == 'wirtualne biuro' && services[i].service_packet == 'komfort' && services[i].status == 2){
          this.reservation = 1;
        }else{
          this.reservation = 0;
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
  div.virtual-office.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 80px);  }
  div.wrapper.center { display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 140px);  }

  div.empty { width: 400px; }
  div.empty div.img { width: 200px; height: 125px; margin: 30px auto; background: url('../assets/workInProgressCloserSmall.png') no-repeat 50% 50%; }
  div.empty h4 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 18px; color: $blackLight; }
  div.empty h5 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 13px; color: $grayLighter; }

  div.reservation { width: 400px; }
  div.reservation div.img { width: 200px; height: 125px; margin: 30px auto; background: url('../assets/workInProgressCloserSmall.png') no-repeat 50% 50%; }
  div.reservation h4 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 18px; color: $blackLight; }
  div.reservation h5 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 13px; color: $grayLighter; }
  div.reservation div h6 { margin: 10px 0; padding: 0 10px; font-weight: 400; text-align: center; font-size: 15px; color: $orangeLight; }

  @media (min-width: 1000px) {
      div#panel.element-menu div.virtual-office { width: calc(100% - 250px); margin-left: 250px; }
  }

  @media (max-width: 410px) {
    div.empty, div.reservation { width: calc(100vw - 20px); }
  }
</style>
