<template>
 <div class="loader">
    <div class="loader-cube loader-cube1" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube2" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube3" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube4" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube5" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube6" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube7" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube8" :class="[getColorClass()]"></div>
    <div class="loader-cube loader-cube9" :class="[getColorClass()]"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  data(){
    return{
      color: 0,
    }
  },
  props: {
    data: Object,
  },
  created(){
    this.color = localStorage.companyColor;
  },
  methods: {
    getColorClass(){
      switch(parseInt(this.color)){
        case 1:
          return 'gradient-orange';
        case 2:
          return 'gradient-blue';
        case 3:
          return 'gradient-red';
        case 4:
          return 'gradient-green';
        case 5:
          return 'gradient-pink';
        case 6:
          return 'gradient-purple';
        default:
          return 'gradient-orange';
      }
    },
  }
}
</script>
<style scoped lang="scss">
  .loader {
    width: 40px;
    height: 40px;
  }
  
  .loader .loader-cube {
    width: 33%;
    height: 33%;
    float: left;
    -webkit-animation: loader-cubeGridScaleDelay 1.3s infinite ease-in-out;
            animation: loader-cubeGridScaleDelay 1.3s infinite ease-in-out; 
  }
  .loader .loader-cube1 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .loader .loader-cube2 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .loader .loader-cube3 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .loader .loader-cube4 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .loader .loader-cube5 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .loader .loader-cube6 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .loader .loader-cube7 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .loader .loader-cube8 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .loader .loader-cube9 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  
  @-webkit-keyframes loader-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1); 
    }
  }
  
  @keyframes loader-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    } 
  }
</style>
