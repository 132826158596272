<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export default {
  name: 'App',
  data(){
    return{
      currentRequest: 0,
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  created(){
    let self = this;
    const refreshAuthLogic = failedRequest => this.axios.get(self.api + 'auth/token', { headers: { Authorization: localStorage.refreshToken } }).then(tokenRefreshResponse => {
      if(tokenRefreshResponse.data.status == 1){
        localStorage.accessToken = tokenRefreshResponse.data.accessToken;
        failedRequest.response.config.headers['Authorization'] = tokenRefreshResponse.data.accessToken;
        return Promise.resolve();
      }else{
        self.logout();
      }
    });

    createAuthRefreshInterceptor(this.axios, refreshAuthLogic, {
      statusCodes: [401],
      pauseInstanceWhileRefreshing: false,
    });
    this.axios.interceptors.request.use(request => {
        let path1 = self.api + 'auth/token';
        let path2 = self.api + 'auth/password';
        if(typeof request.headers['Authorization'] != 'undefined' && request.url != path1 && request.url != path2){
          request.headers['Authorization'] = self.getAccessToken();
        }
        return request;
    });
    if(localStorage.elementMenu == 1){
      this.$store.commit('toggleMenu', 1);
    }
  },
  methods:{
    logout(){
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      this.$router.push('/');
    },
    getAccessToken(){
      return localStorage.getItem('accessToken');
    }
  }
}
</script>

<style lang="scss">
  @import './styles/aspergit.css';

  div#app { height: 100%; }
</style>

