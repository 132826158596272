<template>
  <div id="authorization">
    <hr>
    <hr>
    <div class="img">
      <h1><span>AFK</span> Panel Klienta</h1>
    </div>
    <div class="form">
      <div class="logo"></div>
      <div class="icon">
        <div class="icon-circle">
          <i class="material-icons">{{ getIcon(authType) }}</i>
        </div>
      </div>
      <div class="login" v-if="authType == 1">
        <div class="input-container">
          <input type="text" placeholder="Email" v-model="email">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">email</i>
            </div>
          </div>
        </div>
        <div class="input-container">
          <input type="password" placeholder="Hasło" v-model="password1" @keyup.enter="login()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">lock</i>
            </div>
          </div>
        </div>
        <button class="orange" @click="login()">Zaloguj</button>
        <p class="link" @click="actionType(3)">Przypomnij hasło</p>
        <button @click="actionType(2)">Zarejestruj się</button>
      </div>
      <div class="register" v-if="authType == 2">
        <div class="input-container">
          <input type="text" placeholder="Email" v-model="email">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">email</i>
            </div>
          </div>
        </div>
        <div class="input-container">
          <input type="password" placeholder="Hasło" v-model="password1">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">lock</i>
            </div>
          </div>
        </div>
        <div class="input-container">
          <input type="password" placeholder="Powtórz hasło" v-model="password2" @keyup.enter="register()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">lock</i>
            </div>
          </div>
        </div>
        <button class="orange" @click="register()">Zarejestruj</button>
        <p class="link" @click="actionType(1)">Masz już konto? Zaloguj się</p>
      </div>
      <div class="email" v-if="authType == 3">
        <div class="input-container">
          <input type="text" placeholder="Email" v-model="email" @keyup.enter="sendLink()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">email</i>
            </div>
          </div>
        </div>
        <button class="orange" @click="sendLink()">Przypomnij hasło</button>
        <p class="link" @click="actionType(2)">Rejestracja</p>
        <button @click="actionType(1)">Zaloguj się</button>
      </div>
      <div class="code" v-if="authType == 4">
        <h6>Na maila został wysłany kod aktywacyjny. Proszę sprawdzić folder spam.</h6>
        <div class="input-container">
          <input type="text" placeholder="Kod" v-model="code" @keyup.enter="checkCode()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">email</i>
            </div>
          </div>
        </div>
        <button class="orange" @click="checkCode()">Potwierdź</button>
        <button @click="cancelCode()">Anuluj</button>
        <button class="orange" @click="sendNewCodeRequest()">Wyślij nowy kod</button>
      </div>
      <div class="email" v-if="authType == 5">
        <div class="input-container">
          <input type="password" placeholder="Nowe Hasło" v-model="password1">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">lock</i>
            </div>
          </div>
        </div>
        <div class="input-container">
          <input type="password" placeholder="Powtórz nowe hasło" v-model="password2" @keyup.enter="changePassword()">
          <div class="icon-wrapper">
            <div class="icon-small">
              <i class="material-icons">lock</i>
            </div>
          </div>
        </div>
        <button class="orange" @click="changePassword()">Zmień hasło</button>
        <p class="link" @click="actionType(2)">Rejestracja</p>
        <button @click="actionType(1)">Zaloguj się</button>
      </div>
      <div class="msg" :class="{ green: msgType == 1, red: msgType == 0 }" v-if="msg">
        <p>{{ msgText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authorization',
  data(){
    return{
      authType: 1,
      iconLogin: 'person',
      iconRegister: 'person_add',
      iconEmail: 'email',
      iconLock: 'lock',
      msg: 0,
      msgType: 0,
      msgText: '',
      email: '',
      password1: '',
      password2: '',
      code: '',
    }
  },
  computed:{
    api(){
      return this.$store.state.api;
    },
  },
  created(){
    if(typeof this.$route.query.token != 'undefined'){
      this.actionType(5);
    }
  },
  methods: {
    getIcon(type){
      switch(type){
        case 1:
          return this.iconLogin;
        break;
        case 2:
          return this.iconRegister;
        break;
        case 3:
          return this.iconEmail;
        break;
        case 4:
          return this.iconEmail;
        break;
        case 5:
          return this.iconLock;
        break;
      }
    },
    actionType(type){
      this.email = '';
      this.password1 = '';
      this.password2 = '';
      this.code = '';
      this.authType = type;
    },
    login(){
      this.msg = 0;
      if(this.email == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Email jest puste.";
      }else if(this.password1 == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Hasło jest puste.";
      }else{
        this.loginRequest();
      }
    },
    register(){
      let regexSmall = RegExp('[a-z]');
      let regexBig = RegExp('[A-Z]');
      let regexNumber = RegExp('[0-9]');
      let regexNonWord = RegExp('\\W');
      this.msg = 0;
      if(this.email == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Email jest puste.";
      }else if(this.password1 == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Hasło jest puste.";
      }else if(this.password2 == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Powtórz hasło jest puste.";
      }else if(this.password1 != this.password2){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = 'Podane hasła nie są takie same.';
      }else if(this.password1.length < 8 || this.password1.length > 128){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = 'Hasło musi mieć od 8 do 128 znaków.';
      }else if(!regexSmall.test(this.password1)){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = 'Hasło musi zawierać co najmniej jedną małą literę.';
      }else if(!regexBig.test(this.password1)){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = 'Hasło musi zawierać co najmniej jedną wielką literę.';
      }else if(!regexNumber.test(this.password1)){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = 'Hasło musi zawierać co najmniej jedną cyfrę.';
      }else if(!regexNonWord.test(this.password1)){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = 'Hasło musi zawierać co najmniej jeden znak specjalny.';
      }else{
        this.registerRequest();
      }
    },
    checkCode(){
      this.msg = 0;
      if(this.code == ''){
        this.msg = 1;
        this.msgType = 0;
        this.msgText = "Pole Kod jest puste.";
      }else{
        this.checkCodeRequest()
      }
    },
    cancelCode(){
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      this.actionType(1);
    },
    loginRequest(){
      let self = this;
      this.axios
      .get(this.api + 'auth/login', { params: { email: this.email, password: this.password1 } })
      .then(response => {
        if(response.data.status == 1){
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("accessToken", response.data.accessToken);
          }else{
            localStorage.accessToken = response.data.accessToken;
          }
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("refreshToken", response.data.refreshToken);
          }else{
            localStorage.refreshToken = response.data.refreshToken;
          }
          localStorage.removeItem("accountStatus");
          self.$router.push('/firmy');
        }else if(response.data.status == 2){
          self.actionType(4);
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("accessToken", response.data.accessToken);
          }else{
            localStorage.accessToken = response.data.accessToken;
          }
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("refreshToken", response.data.refreshToken);
          }else{
            localStorage.refreshToken = response.data.refreshToken;
          }
          if(typeof(localStorage.accountStatus) !== "undefined") {
            localStorage.setItem("accountStatus", 2);
          }else{
            localStorage.accountStatus = 2;
          }
        }else{
          self.msg = 1;
          self.msgType = 0;
          self.msgText = response.data.msg;
        }
      });
    },
    registerRequest(){
      let self = this;
      this.axios
      .post(this.api + 'auth/register', { email: this.email, password1: this.password1, password2: this.password2 })
      .then(response => {
        if(response.data.status){
          self.actionType(1);
          self.msg = 1;
          self.msgType = 1;
          self.msgText = response.data.msg;
        }else{
          self.msg = 1;
          self.msgType = 0;
          self.msgText = response.data.msg;
        }
      });
    },
    checkCodeRequest(){
      let self = this;
      this.axios
      .put(this.api + 'auth/activation', { code: this.code, }, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
        if(response.data.status){
          self.exchangeTokenRequest();
        }else{
          self.msg = 1;
          self.msgType = 0;
          self.msgText = response.data.msg;
        }
      });
    },
    exchangeTokenRequest(){
      let self = this;
      this.axios
      .get(this.api + 'auth/token', { headers: { Authorization: localStorage.refreshToken } })
      .then(response => {
        if(response.data.status == 1){
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("accessToken", response.data.accessToken);
          }else{
            localStorage.accessToken = response.data.accessToken;
          }
          if(typeof(localStorage.accessToken) !== "undefined") {
            localStorage.setItem("refreshToken", response.data.refreshToken);
          }else{
            localStorage.refreshToken = response.data.refreshToken;
          }
          localStorage.removeItem("accountStatus");
          self.$router.push('/firmy');
        }else{
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          this.actionType(1);
        }
      });
    },
    sendNewCodeRequest(){
      let self = this;
      this.axios
      .put(this.api + 'auth/activation/new', {}, { headers: { Authorization: localStorage.accessToken } })
      .then(response => {
          self.msg = 1;
          self.msgType = response.data.status;
          self.msgText = response.data.msg;
      });
    },
    sendLink(){
      let self = this;
      this.axios
      .post(this.api + 'auth/password', { email: this.email })
      .then(response => {
        if(response.data.status){
          self.actionType(1);
        }
        self.msg = 1;
        self.msgType = response.data.status;
        self.msgText = response.data.msg;
      });
    },
    changePassword(){
      let self = this;
      this.axios
      .put(this.api + 'auth/password', { password1: this.password1, password2: this.password2 }, { headers: { Authorization: this.$route.query.token } })
      .then(response => {
        if(response.data.status){
          self.actionType(1);
        }
        self.msg = 1;
        self.msgType = response.data.status;
        self.msgText = response.data.msg;
      });
    },
  }
}
</script>
<style scoped lang="scss">
  div#authorization { display: flex; width: 800px; height: 460px; margin: calc(50vh - 230px) auto 0 auto; position: relative; background: $grayLighter2; border-radius: 25px; 
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.8); }

  div#authorization hr { display: block; width: 100%; height: 1px; position: absolute; top: 50px; outline: 0; border: 0; background: #fff; }
  div#authorization hr:nth-child(2) { top: initial; bottom: 50px; }

  div#authorization div.img { width: 422px; height: 460px; border-top-left-radius: 25px; border-bottom-left-radius: 25px; 
    background: url('../assets/authorization.webp') no-repeat 50% 50%; }
  div#authorization div.img h1 { height: 58px; margin: 0; padding: 0; font-size: 24px; color: $orangeLight; font-weight: 300; line-height: 58px;
     background: url('../assets/logo_27_normal.png') no-repeat 25px 50%; font-family: 'Aspergit Bold', sans-serif; letter-spacing: 1px; text-align: center; }
  div#authorization div.img h1 span { display: none;}

  div#authorization div.form { display: flex; flex-direction: column; width: 378px; position: relative; }
  div#authorization div.form div.logo { display: none; width: 57px; height: 27px; position: absolute; left: 25px; top: 15.5px; background: url('../assets/logo_27_white.png') no-repeat 50% 50%; }
  div#authorization div.form div.icon { display: flex; justify-content: center; align-items: center; width: 110px; height: 110px; margin: 5px auto 0 auto; 
    background: $grayLighter2;}
  div#authorization div.form div.icon div.icon-circle { display: flex; justify-content: center; align-items: center; width: 80px; height: 80px;
    border-radius: 50%; border: 2px solid #fff;  }
  div#authorization div.form div.icon div.icon-circle i.material-icons { color: #fff; font-size: 35px; }

  div#authorization div.form div.input-container { display: flex; justify-content: space-between; align-items: center; width: 250px; height: 35px; margin: 20px auto; position: relative;
     background: #fff; border-radius: 3px; }
  div#authorization div.form div.input-container div.icon-wrapper { display: flex; align-items: center; width: 45px; height: 35px; position: absolute;
    background: #fff; border-radius: 3px; }
  div#authorization div.form div.input-container div.icon-small { display: flex; justify-content: center; align-items: center; width: 25px; height: 25px; 
   margin-left: 5px; opacity: 1; }
  div#authorization div.form div.input-container div.icon-small i.material-icons { color: #fff; font-size: 16px; color: $grayLighter2; }
  div#authorization div.form div.input-container input { width: 250px; padding: 0 15px 0 15px; border: 0; outline: 0; text-align: center; border-radius: 3px; 
    color: $blackDark; font-size: 15px; }
  div#authorization div.form div.input-container input:focus { color: $orangeLight; }
  div#authorization div.form div.input-container input:focus + div.icon-wrapper div.icon-small i.material-icons { color: $orangeLight; }

  div#authorization div.form h6 { margin: 0 auto; padding: 0; width: 250px; font-size: 14px; font-weight: 400; color: $blackLight; text-align: center; }

  div#authorization div.form div.msg { display: flex; justify-content: center; align-items: center; width: 378px; height: 58px; margin: auto auto 0 auto; }
  div#authorization div.form div.msg p { width: 250px; margin: 0; padding: 5px; font-size: 12px; color: #fff; text-align: center; border-radius: 3px; }
  div#authorization div.form div.msg.red p { background: $red; }
  div#authorization div.form div.msg.green p { background: $greenLight; }


  div#authorization div.form button { display: block; width: 250px; margin: 20px auto; height: 30px; border: 0; outline: 0; background: $blackLight; color: #fff; 
    border-radius: 3px; font-size: 14px; cursor: pointer; }
  div#authorization div.form button.orange { background: $orangeDark; }

  div#authorization p.link { width: 250px; margin: 20px auto; padding: 0; text-align: center; color: $orangeDark; font-size: 14px; cursor: pointer; }
  div#authorization p.link:hover { text-decoration: underline; }

  @media (max-width: 800px) {
    div#authorization { width: 378px; }
    div#authorization div.img { display: none; }
    div#authorization div.form div.logo { display: block; }
  }

  @media (max-width: 378px) {
    div#authorization { width: calc(100vw - 40px); }
    div#authorization div.form { width: 100%; }
    div#authorization div.form div.msg{ width: 100%; }
  }

  @media (max-width: 290px) {
    div#authorization div.form div.input-container { width: calc(100% - 40px); }
    div#authorization div.form div.input-container input { width: calc(100% - 40px); }
    div#authorization div.form button { width: calc(100% - 40px); }
    div#authorization div.form div.msg { width: calc(100% - 40px); }
  }
</style>
